import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import DataContext from 'context/DataStore/Data.context';
import {
  EMPTY_STATE_ILLUSTRATION_TYPES,
  MIDEmptyState,
  TableEmptyStateWrapper,
  useTableLoadingState,
} from 'mid-react-common';
import { DrawingThumbnail } from 'mid-types';
import { useContext } from 'react';
import text from '../../../../inventor.text.json';
import { TableToolBar } from './Drawing.styles';
import { getColumns } from './drawings.utils';
import useDrawings from './useDrawings';

interface DrawingThumbnailsRow {
  filename: DrawingThumbnail;
}

const Drawings: React.FC = (): JSX.Element => {
  const { currentProductDefinition } = useContext(DataContext);
  const { drawingThumbnails } = currentProductDefinition;
  const { drawingsLoading, handleDrawingsSelection, refetchDrawings } = useDrawings();

  const theme = useTheme();
  const drawingThumbnailsRows: DrawingThumbnailsRow[] = drawingThumbnails
    ? drawingThumbnails.reduce<DrawingThumbnailsRow[]>(
        (rows, drawing) =>
          drawing.relativePath
            ? [
                ...rows,
                {
                  filename: drawing,
                },
              ]
            : rows,
        [],
      )
    : [];
  const selectedDrawingThumbnails: string[] | undefined = drawingThumbnails
    ?.filter((drawing) => drawing.checked)
    .map((drawing) => drawing.relativePath);

  const renderEmptyState = () => (
    <TableEmptyStateWrapper>
      <MIDEmptyState
        illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.FOLDER_EMPTY_GREY}
        description={text.EmptyStateNoDrawing}
        hideTitle
      />
    </TableEmptyStateWrapper>
  );

  const renderRefetchDrawings = () => (
    <TableToolBar>
      <Tooltip title={text.drawingTemplateFilesScan}>
        <IconButton onClick={refetchDrawings} size="small">
          <SyncIcon />
        </IconButton>
      </Tooltip>
    </TableToolBar>
  );

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {text.outputSources}
      </Typography>
      <Box display="inline-flex">
        <Typography gutterBottom fontWeight="bold" marginRight={`${theme.var.marginBase / 2}px`}>
          {text.drawingTemplateFilesTitle}
        </Typography>
        <Tooltip placement="top-start" title={text.drawingTemplateFilesTableInfo} arrow>
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      </Box>
      <DataGrid
        autoHeight
        density="compact"
        hideFooter
        loading={drawingsLoading}
        checkboxSelection
        rowHeight={theme.var.drawingsSize * 1.5}
        columns={getColumns()}
        rows={drawingsLoading ? [] : drawingThumbnailsRows}
        selectionModel={selectedDrawingThumbnails}
        getRowId={(row) => row.filename.relativePath}
        components={{
          NoRowsOverlay: renderEmptyState,
          LoadingOverlay: () => useTableLoadingState({ height: theme.var.drawingsSize }),
          Toolbar: renderRefetchDrawings,
        }}
        onSelectionModelChange={drawingsLoading ? undefined : handleDrawingsSelection}
      />
    </>
  );
};

export default Drawings;
