import { isEqual } from 'lodash';
import {
  ProductDefinition,
  ProductDefinitionIProperty,
  ProductDefinitionInput,
  ProductDefinitionInputParameter,
  ProductDefinitionOutput,
  ProductDefinitionReducerMap,
  InputRule,
  PublishStatus,
  SerializedBlocklyWorkspaceState,
  InventorParameter,
  toProductDefinitionInputParameter,
  ProductDefinitionParameterDefault,
} from 'mid-addin-lib';
import { DrawingThumbnail, MetaInfo, MetaInfoPath } from 'mid-types';
import { StateSetter } from 'mid-react-common';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { CurrentProductDefinitionActionTypes } from './dataStore.actions';
import { productDefinitionReducer } from './dataStore.reducers';
import { OutputTypes } from '@adsk/offsite-dc-sdk';
import { CodeblocksWorkspaceType } from '../../components/BlocklyModule/types';

interface CurrentProductDefinitionSourceModel {
  topLevelFolder?: string;
  inventorProject?: string;
  assembly?: string;
}

export const initialPublishStatus = PublishStatus.IDLE;

export const initialProductDefinition: ProductDefinition = {
  id: '',
  name: '',
  releaseName: '',
  lastUpdated: 0,
  account: { id: '', name: '' },
  project: { id: '', name: '' },
  folder: { id: '', name: '', parentPath: [] },
  topLevelFolder: '',
  inventorProject: '',
  assembly: '',
  thumbnail: '',
  drawingThumbnails: [],
  inputs: [],
  parametersDefaults: [],
  rules: [],
  outputs: [],
};

export const initialProductDefinitionReducer: ProductDefinitionReducerMap = {
  id: '',
  name: '',
  releaseName: '',
  lastUpdated: 0,
  account: { id: '', name: '' },
  project: { id: '', name: '' },
  folder: { id: '', name: '', parentPath: [] },
  topLevelFolder: '',
  inventorProject: '',
  assembly: '',
  thumbnail: '',
  drawingThumbnails: [],
  inputs: new Map<string, ProductDefinitionInput>(),
  parametersDefaults: [],
  rules: [],
  outputs: [],
};

export interface DataStore {
  productDefinitionHasUnsavedChanges: boolean;
  currentProductDefinition: ProductDefinition;
  currentProductDefinitionPublishStatus: PublishStatus;
  backpackContents: string[];
  setBackpackContents: StateSetter<string[]>;
  areDrawingFilesFetched: boolean;
  setAreDrawingFilesFetched: StateSetter<boolean>;
  setCurrentProductDefinitionPublishStatus: StateSetter<PublishStatus>;
  setCurrentProductDefinition: (productDefinition: ProductDefinition) => void;
  setCurrentProductDefinitionName: (newProductDefinitionName: string) => void;
  setCurrentProductDefinitionSourceModel: ({
    topLevelFolder,
    inventorProject,
    assembly,
  }: CurrentProductDefinitionSourceModel) => void;
  setCurrentProductDefinitionThumbnail: (thumbnail: string) => void;
  setCurrentProductDefinitionPublishLocation: (account: MetaInfo, project: MetaInfo, folder: MetaInfoPath) => void;
  setCurrentProductDefinitionRule: (rule: InputRule) => void;
  setCurrentProductDefinitionCodeBlocksWorkspace: (workspaceSerialized: SerializedBlocklyWorkspaceState | undefined) => void;
  setCurrentFormCodeBlocksWorkspace: (workspaceSerialized: SerializedBlocklyWorkspaceState | undefined) => void;
  setCurrentProductDefinitionParametersDefaultsByInventorParameters: (
    inventorParameters: InventorParameter[],
  ) => ProductDefinition['parametersDefaults'];
  setCurrentProductDefinitionParametersDefaults: (parametersDefaults: ProductDefinitionParameterDefault[]) => void;
  setCurrentProductDefinitionDrawingsThumbnails: (drawingThumbnails: DrawingThumbnail[]) => void;
  replaceAllCurrentProductDefinitionInputs: (newParameters: ProductDefinitionInput[]) => void;
  updateCurrentProductDefinitionParameter: (
    parameterToUpdate: ProductDefinitionInputParameter,
    updatedValue: { [key: string]: any },
  ) => void;
  updateCurrentProductDefinitionIProperty: (
    iPropertyToUpdate: ProductDefinitionIProperty,
    updatedValue: { [key: string]: any },
  ) => void;
  addCurrentProductDefinitionOutputOptions: (
    outputType: OutputTypes,
    outputOptions?: ProductDefinitionOutput['options'],
  ) => void;
  removeCurrentProductDefinitionOutput: (outputType: OutputTypes) => void;
  addCurrentProductDefinitionOutputs: (outputs: ProductDefinitionOutput[]) => void;
  deleteCurrentProductDefinitionDrawingOutput: (outputType: OutputTypes, drawingTemplatePath: string) => void;
  deleteCurrentProductDefinitionDrawingOutputs: (drawingTemplatePaths: string[]) => void;
  deleteCurrentProductDefinitionTable: () => void;
  resetCurrentProductDefinition: () => void;
  isErronousProductDefinitionName: {
    error: boolean;
    cause: string | null;
  };
  setIsErronousProductDefinitionName: StateSetter<{
    error: boolean;
    cause: string | null;
  }>;
  setLatestWorkspaceSelected: (workspace: CodeblocksWorkspaceType | undefined) => void;
  latestWorkspaceSelected: CodeblocksWorkspaceType | undefined;
  recentlyAdoptedInputs: ProductDefinitionInput[];
  setRecentlyAdoptedInputs: StateSetter<ProductDefinitionInput[]>;
}

export const useStore = (): DataStore => {
  const [currentProductDefinition, dispatchCurrentProductDefinitionAction] = useReducer(
    productDefinitionReducer,
    initialProductDefinitionReducer,
  );
  const previousProductDefinitionRef = useRef(currentProductDefinition);
  const [productDefinitionHasUnsavedChanges, setProductDefinitionHasUnsavedChanges] = useState<boolean>(false);
  const [currentProductDefinitionPublishStatus, setCurrentProductDefinitionPublishStatus] = useState(initialPublishStatus);
  const [backpackContents, setBackpackContents] = useState<string[]>([]);
  const [areDrawingFilesFetched, setAreDrawingFilesFetched] = useState<boolean>(false);
  const [isErronousProductDefinitionName, setIsErronousProductDefinitionName] = useState<{
    error: boolean;
    cause: string | null;
  }>({ error: false, cause: null });

  const [latestWorkspaceSelected, setLatestWorkspaceSelected] = useState<CodeblocksWorkspaceType | undefined>(
    CodeblocksWorkspaceType.FORM,
  );

  const [recentlyAdoptedInputs, setRecentlyAdoptedInputs] = useState<ProductDefinitionInput[]>([]);

  // Unsaved Changes Tracker
  useEffect(() => {
    const isInitialProductDefinition = isEqual(currentProductDefinition, initialProductDefinitionReducer);
    const isSavedProductDefinition = !!currentProductDefinition.id;

    if (isInitialProductDefinition) {
      setProductDefinitionHasUnsavedChanges(false);
    } else if (!isSavedProductDefinition && !isInitialProductDefinition) {
      setProductDefinitionHasUnsavedChanges(true);
    } else if (isSavedProductDefinition) {
      // If the previous productDefinition's lastUpdated is initialProductDefinition.lastUpdated
      // and the currentProductDefinition is a saved productDefinition then it means
      // the user has clicked "Edit ProductDefinition"
      const initialEditProductDefinitionClick =
        previousProductDefinitionRef.current.lastUpdated === initialProductDefinition.lastUpdated;
      if (initialEditProductDefinitionClick) {
        setProductDefinitionHasUnsavedChanges(false);
      } else {
        // We need to extract the lastUpdated from productDefinition and then compare
        const { lastUpdated: _prevLastUpdated, ...prevProductDefinitionWithoutLastUpdated } =
          previousProductDefinitionRef.current;
        const { lastUpdated: _currentLastUpdated, ...currentProductDefinitionWithoutLastUpdated } = currentProductDefinition;
        const hasUnsavedChanges = !isEqual(
          prevProductDefinitionWithoutLastUpdated,
          currentProductDefinitionWithoutLastUpdated,
        );
        setProductDefinitionHasUnsavedChanges(hasUnsavedChanges);
      }

      // update the previousProductDefinitionRef when a "saved" productDefinition is
      // set to the store (e.g., edit productDefinition) or updated
      previousProductDefinitionRef.current = currentProductDefinition;
    }
  }, [currentProductDefinition]);

  const setCurrentProductDefinition = (productDefinition: ProductDefinition) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_PRODUCT_DEFINITION,
      payload: { productDefinition },
    });
  };

  const setCurrentProductDefinitionParametersDefaults = (parametersDefaults: ProductDefinitionParameterDefault[]) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_PARAMETERS_DEFAULTS,
      payload: { parametersDefaults },
    });
  };

  const setCurrentProductDefinitionParametersDefaultsByInventorParameters = (
    inventorParameters: InventorParameter[],
  ): ProductDefinition['parametersDefaults'] => {
    const parametersDefaults = inventorParameters
      .map(toProductDefinitionInputParameter)
      .map(({ name, value }) => ({ name, value }));

    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_PARAMETERS_DEFAULTS,
      payload: { parametersDefaults },
    });

    return parametersDefaults;
  };

  const setCurrentProductDefinitionName = (newProductDefinitionName: string) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_NAME,
      payload: { newProductDefinitionName },
    });
  };

  const setCurrentProductDefinitionSourceModel = ({
    topLevelFolder,
    inventorProject,
    assembly,
  }: CurrentProductDefinitionSourceModel) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_SOURCE_MODEL,
      payload: { topLevelFolder, inventorProject, assembly },
    });
  };

  const setCurrentProductDefinitionThumbnail = (thumbnail: string) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_PRODUCT_THUMBNAIL,
      payload: { thumbnail },
    });
  };

  // wrapped with useCallback to prevent function re-creation and infinite loops for other standard react hooks which
  // depend on this function
  const setCurrentProductDefinitionPublishLocation = useCallback(
    (account: MetaInfo, project: MetaInfo, folder: MetaInfoPath) => {
      dispatchCurrentProductDefinitionAction({
        type: CurrentProductDefinitionActionTypes.SET_PUBLISH_LOCATION,
        payload: { account, project, folder },
      });
    },
    [dispatchCurrentProductDefinitionAction],
  );

  const replaceAllCurrentProductDefinitionInputs = (newInputs: ProductDefinitionInput[]) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.REPLACE_ALL_INPUTS,
      payload: { newInputs },
    });
  };

  const updateCurrentProductDefinitionParameter = (
    parameterToUpdate: ProductDefinitionInputParameter,
    updatedValue: { [key: string]: any },
  ) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.UPDATE_PARAMETER,
      payload: { parameterToUpdate, updatedValue },
    });
  };

  const updateCurrentProductDefinitionIProperty = (
    iPropertyToUpdate: ProductDefinitionIProperty,
    updatedValue: { [key: string]: any },
  ) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.UPDATE_IPROPERTY,
      payload: { iPropertyToUpdate, updatedValue },
    });
  };

  const addCurrentProductDefinitionOutputOptions = (
    outputType: OutputTypes,
    outputOptions?: ProductDefinitionOutput['options'],
  ) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.ADD_OUTPUT_OPTIONS,
      payload: { outputType, outputOptions },
    });
  };

  const removeCurrentProductDefinitionOutput = (outputType: OutputTypes) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.REMOVE_OUTPUT,
      payload: { outputType },
    });
  };

  const addCurrentProductDefinitionOutputs = (outputs: ProductDefinitionOutput[]) => {
    dispatchCurrentProductDefinitionAction({
      payload: { outputs },
      type: CurrentProductDefinitionActionTypes.ADD_OUTPUTS,
    });
  };

  const deleteCurrentProductDefinitionDrawingOutput = (outputType: OutputTypes, drawingTemplatePath: string) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.DELETE_DRAWING_OUTPUT,
      payload: { outputType, drawingTemplatePath },
    });
  };

  const deleteCurrentProductDefinitionDrawingOutputs = (drawingTemplatePaths: string[]) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.DELETE_DRAWING_OUTPUTS,
      payload: { drawingTemplatePaths },
    });
  };

  const setCurrentProductDefinitionRule = (rule: InputRule) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_RULE,
      payload: { rule },
    });
  };

  const setCurrentProductDefinitionCodeBlocksWorkspace = (
    workspaceSerialized: SerializedBlocklyWorkspaceState | undefined,
  ) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_CODE_BLOCKS_WORKSPACE,
      payload: { workspaceSerialized },
    });
  };

  const setCurrentFormCodeBlocksWorkspace = (workspaceSerialized: SerializedBlocklyWorkspaceState | undefined) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_FORM_CODE_BLOCKS_WORKSPACE,
      payload: { workspaceSerialized },
    });
  };

  const setCurrentProductDefinitionDrawingsThumbnails = (drawingThumbnails: DrawingThumbnail[]) => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.SET_DRAWING_THUMBNAILS,
      payload: { drawingThumbnails },
    });
  };

  const deleteCurrentProductDefinitionTable = () => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.DELETE_TABLE,
    });
  };

  const resetCurrentProductDefinition = () => {
    dispatchCurrentProductDefinitionAction({
      type: CurrentProductDefinitionActionTypes.RESET_PRODUCT_DEFINITION,
    });
  };

  return {
    productDefinitionHasUnsavedChanges,
    currentProductDefinition: {
      ...currentProductDefinition,
      inputs: [...currentProductDefinition.inputs.values()],
    },
    currentProductDefinitionPublishStatus,
    backpackContents,
    setBackpackContents,
    areDrawingFilesFetched,
    setAreDrawingFilesFetched,
    setCurrentProductDefinitionPublishStatus,
    setCurrentProductDefinition,
    setCurrentProductDefinitionName,
    setCurrentProductDefinitionSourceModel,
    setCurrentProductDefinitionThumbnail,
    setCurrentProductDefinitionPublishLocation,
    setCurrentProductDefinitionRule,
    setCurrentProductDefinitionCodeBlocksWorkspace,
    setCurrentFormCodeBlocksWorkspace,
    setCurrentProductDefinitionDrawingsThumbnails,
    setCurrentProductDefinitionParametersDefaults,
    setCurrentProductDefinitionParametersDefaultsByInventorParameters,
    replaceAllCurrentProductDefinitionInputs,
    updateCurrentProductDefinitionParameter,
    updateCurrentProductDefinitionIProperty,
    addCurrentProductDefinitionOutputOptions,
    removeCurrentProductDefinitionOutput,
    addCurrentProductDefinitionOutputs,
    deleteCurrentProductDefinitionDrawingOutput,
    deleteCurrentProductDefinitionDrawingOutputs,
    deleteCurrentProductDefinitionTable,
    resetCurrentProductDefinition,
    isErronousProductDefinitionName,
    setIsErronousProductDefinitionName,
    setLatestWorkspaceSelected,
    latestWorkspaceSelected,
    recentlyAdoptedInputs,
    setRecentlyAdoptedInputs,
  };
};
