import DataContext from 'context/DataStore/Data.context';
import { getModelStates, createFullPath } from 'mid-addin-lib';
import { useContext, useState, useCallback, useEffect } from 'react';

interface UseGetAvailableRepresentationsState {
  availableRepresentations: string[];
}

export const useGetAvailableRepresentations = (): UseGetAvailableRepresentationsState => {
  const { currentProductDefinition } = useContext(DataContext);
  const [availableRepresentations, setAvailableRepresentations] = useState<string[]>([]);
  const fetchModelStates = useCallback(async (iamFileFullPath: string) => {
    const modelStates = await getModelStates(iamFileFullPath);
    setAvailableRepresentations(modelStates);
  }, []);

  useEffect(() => {
    const topLevelFolder = currentProductDefinition.topLevelFolder;
    const assembly = currentProductDefinition.assembly;
    if (topLevelFolder && assembly) {
      fetchModelStates(createFullPath(topLevelFolder, assembly));
    }
  }, [currentProductDefinition.assembly, currentProductDefinition.topLevelFolder, fetchModelStates]);

  return { availableRepresentations };
};
