// This file should be removed once "enableMultiValuesBackwardsCompatibility" flag has been removed
import { DCInputNumericType, DCInputTextType, InputType } from '@adsk/offsite-dc-sdk';
import { ProductDefinition } from 'mid-addin-lib';

export const hasSomeProductDefinitionsWithDeprecatedInputs = (productDefinitions: ProductDefinition[]): boolean =>
  productDefinitions.some((productDefinition) =>
    productDefinition.inputs.some(
      (input) => input.type === InputType.MULTI_VALUE_NUMERIC || input.type === InputType.MULTI_VALUE_TEXT,
    ),
  );

export const getMigratedProductDefinitionMultiValueInputs = (definition: ProductDefinition): ProductDefinition => {
  const migratedInputs = definition.inputs.map((input) => {
    if (input.type === InputType.MULTI_VALUE_NUMERIC) {
      const migratedInput: DCInputNumericType = {
        ...input,
        value: input.value || 0,
        type: InputType.NUMERIC,
        allowCustomValue: true,
      };
      return migratedInput;
    }
    if (input.type === InputType.MULTI_VALUE_TEXT) {
      const migratedInput: DCInputTextType = {
        ...input,
        value: input.value || '',
        type: InputType.TEXT,
        allowCustomValue: true,
      };
      return migratedInput;
    }
    return input;
  });

  const migratedProductDefinition: ProductDefinition = {
    ...definition,
    inputs: migratedInputs,
  };

  // Replacing deprecated types inside the Code Blocks Workspace, if it exists
  if (definition.codeBlocksWorkspace) {
    const migratedCodeBlocksWorkspace = JSON.parse(
      JSON.stringify(definition.codeBlocksWorkspace)
        .replaceAll(InputType.MULTI_VALUE_NUMERIC, InputType.NUMERIC)
        .replaceAll(InputType.MULTI_VALUE_TEXT, InputType.TEXT),
    );
    migratedProductDefinition.codeBlocksWorkspace = migratedCodeBlocksWorkspace;
  }

  return migratedProductDefinition;
};
