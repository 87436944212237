import { OutputTypes } from '@adsk/offsite-dc-sdk';
import { DrawingThumbnail, PDF_FILE_TYPE, drawingTemplateFileTypes, drawingOutputTypes } from 'mid-types';

export const getFileType = (fileName: string): string | null => {
  const lastDotPosition = fileName.lastIndexOf('.');
  return lastDotPosition === -1 ? null : fileName.slice(lastDotPosition + 1);
};

export const getDrawingChipLabels = (selectedDrawings: DrawingThumbnail[]): OutputTypes[] => {
  if (!selectedDrawings.length) {
    return Object.values(drawingOutputTypes);
  }

  const fileTypesOfSelectedDrawing = selectedDrawings.reduce<OutputTypes[]>((currentDrawingTypes, drawing) => {
    const templateType = getFileType(drawing.relativePath);

    if (
      templateType &&
      drawingTemplateFileTypes.includes(templateType) &&
      drawingOutputTypes[templateType] &&
      !currentDrawingTypes.includes(drawingOutputTypes[templateType])
    ) {
      return [...currentDrawingTypes, drawingOutputTypes[templateType]];
    }

    return currentDrawingTypes;
  }, []);

  return [...fileTypesOfSelectedDrawing, drawingOutputTypes[PDF_FILE_TYPE]];
};
