import { ErrorCode } from '../errorCode';
import { CustomError } from '../customError';

type CauseType = {
  error?: Error;
};
export class UpdateProductDefinitionError extends CustomError {
  errorCode = ErrorCode.UpdateProductDefinitionError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
