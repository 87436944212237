import { ProductDefinitionInput, ProductDefinitionOutput, ProductDefinitionReducerMap } from 'mid-addin-lib';
import { initialProductDefinitionReducer } from './dataStore';
import { CurrentProductDefinitionActions, CurrentProductDefinitionActionTypes } from './dataStore.actions';
import { isProductDefinitionIProperty } from 'utils/typeGuards';

const _inputsArrayToMap = (array: ProductDefinitionInput[]) => {
  const map = new Map<string, ProductDefinitionInput>();
  array.forEach((item) => {
    if (isProductDefinitionIProperty(item)) {
      map.set(item.id, item);
    } else {
      map.set(item.name, item);
    }
  });
  return map;
};

export const productDefinitionReducer = (
  productDefinition: ProductDefinitionReducerMap,
  action: CurrentProductDefinitionActions,
): ProductDefinitionReducerMap => {
  switch (action.type) {
    case CurrentProductDefinitionActionTypes.SET_PRODUCT_DEFINITION: {
      const { productDefinition } = action.payload;
      const inputsMap = _inputsArrayToMap(productDefinition.inputs);

      return { ...productDefinition, inputs: inputsMap };
    }
    case CurrentProductDefinitionActionTypes.SET_NAME: {
      const { newProductDefinitionName } = action.payload;
      return { ...productDefinition, name: newProductDefinitionName };
    }
    case CurrentProductDefinitionActionTypes.SET_SOURCE_MODEL: {
      const { topLevelFolder, inventorProject, assembly } = action.payload;
      return {
        ...productDefinition,
        ...(topLevelFolder && { topLevelFolder }),
        ...(inventorProject && { inventorProject }),
        ...(assembly && { assembly }),
      };
    }
    case CurrentProductDefinitionActionTypes.SET_PRODUCT_THUMBNAIL: {
      const { thumbnail } = action.payload;
      return { ...productDefinition, thumbnail };
    }
    case CurrentProductDefinitionActionTypes.SET_PUBLISH_LOCATION: {
      const { account, project, folder } = action.payload;
      return { ...productDefinition, account, project, folder };
    }
    case CurrentProductDefinitionActionTypes.SET_RULE: {
      const { rule } = action.payload;

      const existingRuleIndex = productDefinition.rules.findIndex((existingRule) => existingRule.key === rule.key);

      // If rule exists, update it
      if (existingRuleIndex !== -1) {
        const updatedRules = [...productDefinition.rules];
        updatedRules[existingRuleIndex] = rule;
        return { ...productDefinition, rules: updatedRules };
      }

      // otherwise, add it
      return { ...productDefinition, rules: [...productDefinition.rules, rule] };
    }
    case CurrentProductDefinitionActionTypes.SET_CODE_BLOCKS_WORKSPACE: {
      const { workspaceSerialized } = action.payload;
      return { ...productDefinition, codeBlocksWorkspace: workspaceSerialized };
    }
    case CurrentProductDefinitionActionTypes.SET_FORM_CODE_BLOCKS_WORKSPACE: {
      const { workspaceSerialized } = action.payload;
      return { ...productDefinition, formCodeBlocksWorkspace: workspaceSerialized };
    }

    case CurrentProductDefinitionActionTypes.SET_PARAMETERS_DEFAULTS: {
      return { ...productDefinition, parametersDefaults: action.payload.parametersDefaults };
    }

    case CurrentProductDefinitionActionTypes.SET_DRAWING_THUMBNAILS: {
      const { drawingThumbnails } = action.payload;
      return { ...productDefinition, drawingThumbnails };
    }
    case CurrentProductDefinitionActionTypes.REPLACE_ALL_INPUTS: {
      const { newInputs } = action.payload;
      const inputsMap = _inputsArrayToMap(newInputs);
      return { ...productDefinition, inputs: inputsMap };
    }
    case CurrentProductDefinitionActionTypes.ADD_OUTPUTS: {
      const { outputs: addedOutputs } = action.payload;

      return {
        ...productDefinition,
        outputs: [...productDefinition.outputs, ...addedOutputs],
      };
    }
    case CurrentProductDefinitionActionTypes.UPDATE_PARAMETER: {
      const { parameterToUpdate, updatedValue } = action.payload;
      const clonedInputsMap = new Map(productDefinition.inputs);
      const updatedParameter = {
        ...parameterToUpdate,
        ...updatedValue,
      };
      clonedInputsMap.set(parameterToUpdate.name, updatedParameter);
      return { ...productDefinition, inputs: clonedInputsMap };
    }
    case CurrentProductDefinitionActionTypes.UPDATE_IPROPERTY: {
      const { iPropertyToUpdate, updatedValue } = action.payload;
      const clonedInputsMap = new Map(productDefinition.inputs);
      const updatedIProperty = {
        ...iPropertyToUpdate,
        ...updatedValue,
      };
      clonedInputsMap.set(iPropertyToUpdate.id, updatedIProperty);
      return { ...productDefinition, inputs: clonedInputsMap };
    }
    case CurrentProductDefinitionActionTypes.ADD_OUTPUT_OPTIONS: {
      const { outputType, outputOptions } = action.payload;
      const existingOutput: ProductDefinitionOutput | undefined = productDefinition.outputs.find(
        (output) => output.type === outputType,
      );

      const updatedOutput: ProductDefinitionOutput = {
        // We spread the existingOutput, so we don't change
        // anything besides adding output options
        ...existingOutput,
        type: outputType,
        ...(outputOptions && {
          options: {
            ...existingOutput?.options,
            ...outputOptions,
          },
        }),
      };

      const otherOutputs = productDefinition.outputs.filter((output) => output.type !== outputType);
      return { ...productDefinition, outputs: [...otherOutputs, updatedOutput] };
    }
    case CurrentProductDefinitionActionTypes.REMOVE_OUTPUT: {
      const { outputType } = action.payload;
      return { ...productDefinition, outputs: productDefinition.outputs.filter((output) => output.type !== outputType) };
    }
    case CurrentProductDefinitionActionTypes.RESET_PRODUCT_DEFINITION: {
      return { ...initialProductDefinitionReducer };
    }
    case CurrentProductDefinitionActionTypes.DELETE_DRAWING_OUTPUT: {
      const { outputType, drawingTemplatePath } = action.payload;
      const updatedOutputs = productDefinition.outputs.filter(
        (output) => !(output.type === outputType && output?.options?.drawingTemplatePath === drawingTemplatePath),
      );

      return {
        ...productDefinition,
        outputs: updatedOutputs,
      };
    }
    case CurrentProductDefinitionActionTypes.DELETE_DRAWING_OUTPUTS: {
      const { drawingTemplatePaths } = action.payload;
      const updatedOutputs = productDefinition.outputs.filter(
        (output) =>
          !(output?.options?.drawingTemplatePath && drawingTemplatePaths.includes(output.options.drawingTemplatePath)),
      );

      return {
        ...productDefinition,
        outputs: updatedOutputs,
      };
    }
    default:
      throw new Error('No Product Definition action found');
  }
};
