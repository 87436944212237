import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Blockly from 'blockly';
import {
  InputRule,
  ProductDefinitionInput,
  ProductDefinitionInputParameter,
  SerializedBlocklyWorkspaceState,
} from 'mid-addin-lib';
import { BlocklyEvent } from '../types';
import { BLOCKLY_EVENTS_TO_UPDATE } from '../constants';
import { formCodeblocksToolbox } from './FormCodeblocks.toolbox';
import { initializeFormCodeblocksExtensions } from './FormCodeblocks.extensions';
import { handleWorkspaceSwitch } from '../utils';
import { addNewInputsToWorkspace, disableOrphanedBlocks, initializeFormBlocks } from './FormCodeblocks.utils';
import { isNotProductDefinitionIProperty } from 'utils/typeGuards';
import { debounce } from 'lodash';
import { DATA_STORE_DEBOUNCE_TIME, formRulesKey } from './FormCodeblocks.constants';
import { javascriptGenerator } from 'blockly/javascript';

interface useFormCodeblocksProps {
  hidden: boolean;
  inputs: ProductDefinitionInput[];
  formCodeblocksWorkspace?: SerializedBlocklyWorkspaceState;
  setFormCodeBlocksWorkspace: (workspace: SerializedBlocklyWorkspaceState) => void;
  setFormCodeBlocksRules: (rule: InputRule) => void;
  recentlyAdoptedInputs: ProductDefinitionInput[];
  setRecentlyAdoptedInputs: (inputs: ProductDefinitionInput[]) => void;
}
interface useFormCodeblocksReturn {
  blocklyDiv: React.RefObject<HTMLDivElement>;
}

const useFormCodeblocks = ({
  hidden,
  inputs,
  formCodeblocksWorkspace,
  setFormCodeBlocksWorkspace,
  setFormCodeBlocksRules,
  recentlyAdoptedInputs,
  setRecentlyAdoptedInputs,
}: useFormCodeblocksProps): useFormCodeblocksReturn => {
  const [initialBlocksLoaded, setInitialBlocksLoaded] = useState(false);
  const blocklyDiv = useRef<HTMLDivElement>(null);
  const workspace = useRef<Blockly.WorkspaceSvg>();
  const isWorkspaceDoneLoading = useRef<boolean>(false);

  // We only need the initial list of inputs to initialize the workspace.
  // We don't want to reinitialize the workspace when the inputs change.
  // Hence, we only reference the inputs
  const inputsRef = useRef<ProductDefinitionInputParameter[]>(inputs.filter(isNotProductDefinitionIProperty));

  const getFormCode = useCallback(() => {
    javascriptGenerator.STATEMENT_PREFIX = '';
    const codeWithComments = javascriptGenerator.workspaceToCode(workspace.current);

    //Strip comments from code
    return codeWithComments.replace(/\/\/.*/g, '');
  }, []);

  const saveWorkspace = useMemo(
    () =>
      debounce((workspace: Blockly.WorkspaceSvg) => {
        if (workspace.isDragging()) {
          return;
        }

        const newWorkspace = Blockly.serialization.workspaces.save(workspace);
        setFormCodeBlocksWorkspace(newWorkspace);

        setFormCodeBlocksRules({
          key: formRulesKey,
          code: getFormCode(),
        });
      }, DATA_STORE_DEBOUNCE_TIME),
    [getFormCode, setFormCodeBlocksRules, setFormCodeBlocksWorkspace],
  );

  // We need to keep a reference to the debounced function to cancel it when the component unmounts
  const saveWorkspaceRef = useRef(saveWorkspace);

  const handleWorkspaceChange = useCallback(
    (event: BlocklyEvent): void => {
      if (event.type === Blockly.Events.FINISHED_LOADING && workspace.current) {
        isWorkspaceDoneLoading.current = true;

        addNewInputsToWorkspace(recentlyAdoptedInputs, workspace.current);
        setRecentlyAdoptedInputs([]);

        // Set initial preview for rules once workspace is done loading
        const initialCode = getFormCode();
        if (initialCode) {
          setFormCodeBlocksRules({
            key: formRulesKey,
            code: initialCode,
          });
        }
      }
      if (isWorkspaceDoneLoading.current && workspace.current && BLOCKLY_EVENTS_TO_UPDATE.includes(event.type)) {
        saveWorkspace(workspace.current);
      }
    },
    [getFormCode, recentlyAdoptedInputs, saveWorkspace, setFormCodeBlocksRules, setRecentlyAdoptedInputs],
  );

  useEffect(() => {
    const cancelDebounceBeforeUnmount = saveWorkspaceRef.current;
    if (blocklyDiv.current) {
      workspace.current = Blockly.inject(blocklyDiv.current, {
        toolbox: formCodeblocksToolbox,
      });

      initializeFormCodeblocksExtensions(inputsRef.current);
      workspace.current.addChangeListener(disableOrphanedBlocks);
    }
    return () => {
      if (workspace.current) {
        cancelDebounceBeforeUnmount.cancel();
        workspace.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    handleWorkspaceSwitch(workspace.current, hidden);
  }, [hidden]);

  useEffect(() => {
    if (workspace.current && !initialBlocksLoaded) {
      if (formCodeblocksWorkspace) {
        Blockly.serialization.workspaces.load(formCodeblocksWorkspace, workspace.current);
      } else {
        initializeFormBlocks(workspace.current, inputsRef.current);
      }

      workspace.current.scrollCenter();
      workspace.current.addChangeListener(handleWorkspaceChange);

      setInitialBlocksLoaded(true);
    }
  }, [formCodeblocksWorkspace, getFormCode, handleWorkspaceChange, initialBlocksLoaded, setFormCodeBlocksRules]);

  return { blocklyDiv };
};

export default useFormCodeblocks;
