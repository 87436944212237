import { useState, useEffect, RefObject, useCallback } from 'react';

const useFormResizeObserver = (formRef: RefObject<Element>): number => {
  const [formWidth, setFormWidth] = useState(0);

  const handleFormResize = useCallback((entries: ResizeObserverEntry[]) => {
    const formDiv = entries[0];
    const { width } = formDiv.contentRect;
    setFormWidth(Math.round(width));
  }, []);

  // Initialize ResizeObserver
  useEffect(() => {
    const { current } = formRef;
    const resizeObserver = new ResizeObserver(handleFormResize);
    if (current) {
      resizeObserver.observe(current);
    }
    return () => {
      if (current) {
        resizeObserver.unobserve(current);
      }
      resizeObserver.disconnect();
    };
  }, [formRef, handleFormResize]);

  return formWidth;
};

export default useFormResizeObserver;
