import Blockly, { BlockSvg, FieldDropdown } from 'blockly';
import { blocklyDropdown, invalidInputSymbol } from '../constants';
import { BlocklyState } from 'mid-types';
import { CONTROL_BLOCK_MUTATOR, FORM_CONTAINER_BLOCK_MUTATOR, blocklyLabel } from './FormCodeblocks.constants';
import text from '../../../inventor.text.json';
import {
  dropdownHasInvalidInput,
  isCurrentSelectedOptionValid,
  isSelectedInputNotValid,
  removeInvalidInput,
  updateInputFieldDropdown,
} from '../utils';

// TODO: consolidate logic for mutators
// https://jira.autodesk.com/browse/TRADES-5733

if (!Blockly.Extensions.isRegistered(CONTROL_BLOCK_MUTATOR)) {
  Blockly.Extensions.registerMutator(CONTROL_BLOCK_MUTATOR, {
    saveExtraState(this: BlockSvg) {
      const inputsDropdown = this.getField(blocklyDropdown) as FieldDropdown;
      const labelInput = this.getField(blocklyLabel);
      if (inputsDropdown && labelInput) {
        const inputsDropdownOptions = inputsDropdown.getOptions();
        const selectedOption = inputsDropdown.getText();
        //Check if the current value of the dropdown has a valid adopted parameter
        if (isCurrentSelectedOptionValid(selectedOption) && dropdownHasInvalidInput(inputsDropdownOptions)) {
          //Remove warning and enable block
          this.setEnabled(true);
          this.setWarningText(null);

          //Remove invalid input from dropdown
          const updatedDropdown = new Blockly.FieldDropdown(() => removeInvalidInput(inputsDropdownOptions));
          updateInputFieldDropdown(this, updatedDropdown, blocklyDropdown);
        }

        return {
          inputsDropdown: inputsDropdown.getValue(),
          label: labelInput.getValue(),
          unadopted: !isCurrentSelectedOptionValid(selectedOption),
        };
      }
    },
    loadExtraState(this: BlockSvg, state: BlocklyState) {
      const inputsDropdown = this.getField(blocklyDropdown) as FieldDropdown;
      const labelInput = this.getField(blocklyLabel);
      if (inputsDropdown && labelInput) {
        const inputsDropdownOptions = inputsDropdown.getOptions();
        if (isSelectedInputNotValid(inputsDropdownOptions, state.inputsDropdown)) {
          const label = state.inputsDropdown;
          // If it has been unadopted show warning and disable block
          this.setEnabled(false);
          this.setWarningText(label + text.inputHasNotBeenAdopted);

          // Add unadopted parameter to the dropdown with a warning symbol
          const dropdownWithUnadoptedInput = new Blockly.FieldDropdown(() => [
            [invalidInputSymbol + ' ' + label, state.inputsDropdown],
            ...inputsDropdownOptions,
          ]);

          updateInputFieldDropdown(this, dropdownWithUnadoptedInput, blocklyDropdown);

          // Get updated Inputs Dropdown and set value
          const latestInputsDropdown = this.getField(blocklyDropdown);
          if (latestInputsDropdown) {
            latestInputsDropdown.setValue(state.inputsDropdown);
          }
        } else {
          inputsDropdown.setValue(state.inputsDropdown);
          labelInput.setValue(state.label);

          // if the parameter was unadopted, but now it is, the enabled state should be restored
          if (state.unadopted) {
            this.setEnabled(true);
          }
        }
      }
    },
  });
}

if (!Blockly.Extensions.isRegistered(FORM_CONTAINER_BLOCK_MUTATOR)) {
  Blockly.Extensions.registerMutator(FORM_CONTAINER_BLOCK_MUTATOR, {
    saveExtraState(this: BlockSvg) {
      const formNameField = this.getField(blocklyLabel);
      if (formNameField) {
        return {
          formName: formNameField.getValue(),
        };
      }
    },
    loadExtraState(this: BlockSvg, state: BlocklyState) {
      const formNameField = this.getField(blocklyLabel) as FieldDropdown;
      if (formNameField) {
        formNameField.setValue(state.formName);
      }
    },
  });
}
