import { blocklyKinds } from '../constants';
import text from 'inventor.text.json';
import { controlBlock, groupBlock } from './FormCodeblocks.constants';

export const formCodeblocksToolbox = {
  kind: blocklyKinds.CATEGORY_TOOLBOX,
  contents: [
    {
      kind: blocklyKinds.CATEGORY,
      name: text.formCodeblocksCategoryName,
      contents: [
        {
          kind: blocklyKinds.BLOCK,
          type: controlBlock,
        },
        {
          kind: blocklyKinds.BLOCK,
          type: groupBlock,
        },
      ],
    },
  ],
};
