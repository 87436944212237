import { InputType } from '@adsk/offsite-dc-sdk';
import { GridSelectionModel } from '@mui/x-data-grid';
import { ProductDefinitionIProperty, ProductDefinitionInputParameter } from 'mid-addin-lib';
import { useEffect, useState } from 'react';

export const useTransformToTableIds = (
  items: ProductDefinitionInputParameter[] | ProductDefinitionIProperty[],
): GridSelectionModel => {
  const [selectedIds, setSelectedIds] = useState<GridSelectionModel>([]);

  useEffect(() => {
    const ids: GridSelectionModel = items.map((item) => (item.type !== InputType.IPROPERTY ? item.name : item.id));
    setSelectedIds(ids);
  }, [items]);

  return selectedIds;
};
