import LockIcon from '@mui/icons-material/Lock';
import { FormControl } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@mui/material/styles';
import text from 'inventor.text.json';
import { TextFieldWrapper } from 'mid-react-common';
import React from 'react';
import { sourceContentTestIds } from '../../../../tests/helpers/dataTestIds';

interface BaseModelSectionProps {
  topLevelAssembly: string | undefined;
}

const BaseModelSection: React.FC<BaseModelSectionProps> = ({ topLevelAssembly }): JSX.Element => {
  const theme = useTheme();
  return (
    <FormControl>
      <FormLabel>{text.sourceContentBaseModel}</FormLabel>
      <TextFieldWrapper
        width={theme.var.wideInputControl}
        size="small"
        type="text"
        disabled
        data-testid={sourceContentTestIds.topAssemblyInput}
        value={topLevelAssembly}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <LockIcon />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default BaseModelSection;
