import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';

export const OutputsWrapper = styled('div')`
  padding: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
  max-height: calc(100vh - ${({ theme }) => `${theme.var.topBars}px`} - ${({ theme }) => `${theme.var.paddingBase * 4}px`});
  overflow: auto;
`;

export const OutputRepresentationsTable = styled(Table)`
  grid-area: table;
`;

export const OutputGrid = styled('div')`
  width: 100%;
  display: inline-grid;
  grid-template-columns: 2fr 3fr;
  grid-template-areas: '. table';
  margin-block: ${({ theme }) => `${theme.var.marginBase * 2}px`};
  column-gap: ${({ theme }) => `${theme.var.marginBase * 2}px`};
`;

export const QuestionTooltipIcon = styled(Tooltip)`
  margin-left: ${({ theme }) => `${theme.var.marginBase / 2}px`};
  vertical-align: middle;
`;
