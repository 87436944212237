import { GridColDef } from '@mui/x-data-grid';
import text from 'inventor.text.json';

const IPropertyCategories = {
  USER: 'User',
  CUSTOM: 'Custom',
};

export const getParameterTableColumns = (): GridColDef[] => [
  {
    field: text.inputsTableColumnNameId,
    headerName: text.inputsTableColumnNameHeader,
    flex: 1,
  },
  {
    field: text.inputsTableColumnParameterTypeId,
    headerName: text.inputsTableColumnParameterTypeHeader,
    flex: 1,
  },
  {
    field: text.inputsTableColumnUnitId,
    headerName: text.inputsTableColumnUnitHeader,
    flex: 1,
  },
  {
    field: text.inputsTableColumnValueId,
    headerName: text.inputsTableColumnDefaultValueHeader,
    flex: 1,
  },
  {
    field: text.inputsTableColumnIsKeyId,
    headerName: text.inputsTableColumnIsKeyHeader,
  },
];

export const getIPropertiesTableColumns = (): GridColDef[] => [
  {
    field: text.inputsTableColumnDisplayNameId,
    headerName: text.inputsTableColumnNameHeader,
    flex: 1,
  },
  {
    field: text.inputsTableColumnCategoryId,
    headerName: text.inputsTableColumnCategoryHeader,
    flex: 1,
    renderCell: ({ value }) => <>{value === IPropertyCategories.USER ? IPropertyCategories.CUSTOM : value}</>,
  },
  {
    field: text.inputsTableColumnValueId,
    headerName: text.inputsTableColumnValueHeader,
    flex: 1,
  },
];
