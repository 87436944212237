import text from 'inventor.text.json';
import { useEffect, useMemo, useState } from 'react';

export const InputHeaderCurrentFilterKeys = {
  parameters: 'parameters',
  properties: 'properties',
} as const;

export type InputHeaderCurrentFilter = {
  [InputHeaderCurrentFilterKeys.parameters]: string;
  [InputHeaderCurrentFilterKeys.properties]: string;
};

export interface UseInputsHeaderState {
  isParameter: boolean;
  buttonGroupValue: string;
  handleOnParameterOriPropertyButtonChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void;
  currentFilter: InputHeaderCurrentFilter;
  setCurrentFilter: React.Dispatch<React.SetStateAction<InputHeaderCurrentFilter>>;
  filterItems: string[];
}

export const useInputsHeader = (): UseInputsHeaderState => {
  const [isParameter, setIsParameter] = useState(true);
  const [buttonGroupValue, setButtonGroupValue] = useState(text.inputsParameters);
  const [currentFilter, setCurrentFilter] = useState<InputHeaderCurrentFilter>({
    parameters: text.inputsFilterOptionUser,
    properties: text.inputsFilterOptionAll,
  });
  const [filterItems, setFilterItems] = useState<string[]>([]);

  const parametersItems = useMemo(
    () => [
      text.inputsFilterOptionAll,
      text.inputsFilterOptionModel,
      text.inputsFilterOptionUser,
      text.inputsFilterOptionKey,
      text.inputsFilterOptionSelected,
    ],
    [],
  );

  const iPropertiesItems = useMemo(
    () => [text.inputsFilterOptionAll, text.inputsFilterOptionWithValue, text.inputsFilterOptionSelected],
    [],
  );

  // Set filter items based on isParameter
  useEffect(() => {
    setFilterItems(isParameter ? parametersItems : iPropertiesItems);
    setButtonGroupValue(isParameter ? text.inputsParameters : text.inputsProperties);
  }, [iPropertiesItems, isParameter, parametersItems]);

  const handleOnParameterOriPropertyButtonChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: any) =>
    // Timeout needed for --> https://github.com/mui/mui-x/issues/2714
    setTimeout(() => setIsParameter(value === text.inputsParameters));

  return {
    isParameter,
    buttonGroupValue,
    currentFilter,
    setCurrentFilter,
    filterItems,
    handleOnParameterOriPropertyButtonChange,
  };
};
