import FormLabel from '@mui/material/FormLabel';
import text from 'inventor.text.json';
import { SelectWrapper } from 'mid-react-common';
import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { SelectProps } from '@mui/material/Select';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { sourceContentTestIds } from '../../../../tests/helpers/dataTestIds';
import { TopLevelFolderOptions } from '../useSourceContent';
import { ListItemTextWrapper } from './RootFolderSection.styles';

interface RootFolderSectionProps {
  topLevelFolder: string;
  topLevelFolderOptions: TopLevelFolderOptions[];
  handleTopLevelFolderChange: (newTopLevelFolder: string) => void;
}

const NO_FOLDER_OPTION = '';

const RootFolderSection: React.FC<RootFolderSectionProps> = ({
  topLevelFolder,
  topLevelFolderOptions,
  handleTopLevelFolderChange,
}): JSX.Element => {
  const onChange: SelectProps<unknown>['onChange'] = (event) => {
    if (typeof event.target.value === 'string') {
      handleTopLevelFolderChange(event.target.value);
    }
  };

  return (
    <FormControl size="small" required>
      <FormLabel>{text.sourceContentRootFolder}</FormLabel>
      <SelectWrapper
        value={topLevelFolderOptions.length ? topLevelFolder : NO_FOLDER_OPTION}
        renderValue={(value) => (typeof value === 'string' ? value : NO_FOLDER_OPTION)}
        onChange={onChange}
        data-testid={sourceContentTestIds.topFolderSelection}
      >
        {topLevelFolderOptions.map((folderOption, index) => (
          <MenuItem key={folderOption.path} value={folderOption.path} data-id={folderOption.path}>
            <FolderOpenIcon />
            <ListItemTextWrapper itemIndex={index}>{folderOption.folder}</ListItemTextWrapper>
          </MenuItem>
        ))}
      </SelectWrapper>
    </FormControl>
  );
};

export default RootFolderSection;
