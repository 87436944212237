import { ProductDefinitionOutput } from 'mid-addin-lib';
import { OutputType } from '@adsk/offsite-dc-sdk';
import { DropdownItem } from 'mid-react-common';
import { logError } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import DataContext from '../../../../context/DataStore/Data.context';
import { revitFamilyCategories } from '../constants';
import { debounce } from 'lodash';

interface UseRevitCategoryAndFamilySelectorState {
  revitFamilyCategory: DropdownItem | null;
  localRevitFamily: string;
  revitFamilyCategoryList: DropdownItem[];
  handleOnSelectCategory: (changes: { selectedItem?: DropdownItem | null }) => void;
  handleRevitFamilyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useRevitCategoryAndFamilySelector = (): UseRevitCategoryAndFamilySelectorState => {
  const { currentProductDefinition, addCurrentProductDefinitionOutputOptions } = useContext(DataContext);
  // All dropdown items used by Category dropdown on Output tab - Revit Family Output
  const revitFamilyCategoryList: DropdownItem[] = revitFamilyCategories.map((category) => ({
    label: category,
    value: category,
  }));
  const currentProductDefinitionRevitFamilyOutput: ProductDefinitionOutput | undefined =
    currentProductDefinition.outputs.find((output) => output.type === OutputType.RFA);
  const revitFamilyCategory = currentProductDefinitionRevitFamilyOutput?.options?.category;
  const revitFamilyCategoryDropdownItem: DropdownItem | null = revitFamilyCategory
    ? {
        label: revitFamilyCategory,
        value: revitFamilyCategory,
      }
    : null;

  const [localRevitFamily, setLocalRevitFamily] = useState(
    currentProductDefinitionRevitFamilyOutput?.options?.family || currentProductDefinition.name,
  );

  const debounceUpdateRevitFamilyOutputOptions = debounce(
    (revitFamilyOutputOptions: ProductDefinitionOutput['options']) =>
      addCurrentProductDefinitionOutputOptions(OutputType.RFA, revitFamilyOutputOptions),
    500,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRFAOutputDebounced = useCallback(
    debounceUpdateRevitFamilyOutputOptions,
    [], // Creating it only once initially.
  );

  useEffect(() => {
    if (localRevitFamily !== currentProductDefinitionRevitFamilyOutput?.options?.family) {
      const updatedOptions: ProductDefinitionOutput['options'] = {
        family: localRevitFamily,
      };
      onRFAOutputDebounced(updatedOptions);
    }
  }, [currentProductDefinitionRevitFamilyOutput?.options?.family, localRevitFamily, onRFAOutputDebounced]);

  const handleOnSelectCategory = (changes: { selectedItem?: DropdownItem | null }) => {
    if (!changes.selectedItem) {
      logError('Error: User must select a revit category from the dropdown.');
    } else {
      const updatedOptions: ProductDefinitionOutput['options'] = {
        category: changes.selectedItem.value.toString(),
      };
      addCurrentProductDefinitionOutputOptions(OutputType.RFA, updatedOptions);
    }
  };

  const handleRevitFamilyChange = (event: React.ChangeEvent<HTMLInputElement>) => setLocalRevitFamily(event.target.value);

  return {
    revitFamilyCategory: revitFamilyCategoryDropdownItem,
    localRevitFamily,
    revitFamilyCategoryList,
    handleOnSelectCategory,
    handleRevitFamilyChange,
  };
};
