import Blockly from 'blockly';
import { codeRunner } from '@adsk/informed-design-code-runner';
import {
  ProductDefinition,
  ProductDefinitionInput,
  ProductDefinitionInputParameter,
  convertDCInputsToProductDefinitionInputs,
  createFullPath,
  getPartOrAssemblyProperties,
} from 'mid-addin-lib';
import { NOTIFICATION_STATUSES, NotificationContext, StateSetter } from 'mid-react-common';
import {
  CreateProductDefinitionError,
  ErrorCode,
  ForgeValidationError,
  isBooleanInput,
  isDynamicContentIProperty,
  isNumericInput,
  isTextInput,
  logError,
} from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import DataContext from '../../../context/DataStore/Data.context';
import text from '../../../inventor.text.json';
import { getNotificationBody } from '../../../utils/productDefinition';
import TabProgressContext from 'context/TabProgressStore/TabProgress.context';
import { updateModelAndProductDefinitionInputs } from '../../Publishing/usePublishing.utils';
import { DCInput, InputType } from '@adsk/offsite-dc-sdk';
import { formRulesKey } from '../FormCodeblocks/FormCodeblocks.constants';
import { FormRules } from 'mid-types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isUndefined } from 'lodash';

interface useProductFormPreviewProps {
  blocklyWorkspace: Blockly.WorkspaceSvg | undefined;
  getCode: (highlightBlocks?: boolean) => string;
  showMessageDialog: (message: string) => void;
  setUpdateFormEnabled: StateSetter<boolean>;
}

interface useProductFormPreviewReturn {
  handleSetModelValues: () => void;
  handleInputUpdate: (payload: DCInput) => Promise<void>;
  handleResetToDefaults: () => void;
  handleGetModelValues: () => void;
  handleUpdateForm: () => Promise<void>;
  inputsError: ForgeValidationError | undefined;
  currentFormRules?: FormRules;
  isFormLoading: boolean;
}

export const useProductFormPreview = ({
  blocklyWorkspace,
  getCode,
  showMessageDialog,
  setUpdateFormEnabled,
}: useProductFormPreviewProps): useProductFormPreviewReturn => {
  const {
    currentProductDefinition,
    setCurrentProductDefinition,
    setCurrentProductDefinitionParametersDefaultsByInventorParameters,
    replaceAllCurrentProductDefinitionInputs,
  } = useContext(DataContext);
  const { setHasInputsError } = useContext(TabProgressContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);
  const [inputsError, setInputsError] = useState<ForgeValidationError | undefined>();
  const [runRulesOnInitialInputs, setRunRulesOnInitialInputs] = useState(true);
  const [currentFormRules, setCurrentFormRules] = useState<FormRules | undefined>();
  const { enableFormLayout } = useFlags();

  const applyRulesToProductDefinitionInputs = useCallback(
    async (formInputs: ProductDefinitionInput[]) => {
      if (!blocklyWorkspace) {
        logError(text.blocklyWorkspaceNotInitializedRulesNotApplied);
        showNotification({
          message: text.blocklyWorkspaceNotInitializedRulesNotApplied,
          severity: NOTIFICATION_STATUSES.WARNING,
        });
        return;
      }
      const blocklyCode = getCode(true);
      const { error, result, faultyBlockId } = await codeRunner({
        code: blocklyCode,
        inputs: formInputs,
        printCallback: showMessageDialog,
      });

      // Highlight the block that caused the error, otherwise remove the highlight
      if (faultyBlockId) {
        blocklyWorkspace.highlightBlock(faultyBlockId);
      } else {
        blocklyWorkspace.highlightBlock(null);
      }

      // We only show errors that are related to the code blocks, not validation errors
      if (error && error.errorCode === ErrorCode.CodeRunnerError) {
        logAndShowNotification({
          message: error.message,
          severity: NOTIFICATION_STATUSES.ERROR,
          messageBody: getNotificationBody(error.errors.map((error) => error.detail)),
        });
      }

      const productDefinitionInputs = convertDCInputsToProductDefinitionInputs(result);
      // This just adds the inputs to the current product definition in the
      // dataStore, the user will lose his changes if he closes the addin & returns
      // The user has a "save" button where they can update the product definition
      replaceAllCurrentProductDefinitionInputs(productDefinitionInputs);

      setInputsError(error || undefined);
      const inputsErrorExists = error && error.errors.some((error) => !error.proposedValue);
      setHasInputsError(!!inputsErrorExists);

      if (enableFormLayout) {
        // If the user has saved the form rules, we need to update the form rules
        const currentFormRules = currentProductDefinition.rules.find((rule) => rule.key === formRulesKey);
        if (currentFormRules) {
          try {
            const parsedFormRules: FormRules = JSON.parse(currentFormRules.code);
            setCurrentFormRules(JSON.parse(currentFormRules.code));
            if (parsedFormRules.inputs.length <= 0) {
              setHasInputsError(true);
            }
          } catch (e) {
            setHasInputsError(true);
            showNotification({
              message: text.blocklyFormRulesAreInvalid,
              severity: NOTIFICATION_STATUSES.ERROR,
            });
          }
        }
      }
    },
    [
      blocklyWorkspace,
      getCode,
      showMessageDialog,
      replaceAllCurrentProductDefinitionInputs,
      setHasInputsError,
      enableFormLayout,
      showNotification,
      logAndShowNotification,
      currentProductDefinition.rules,
    ],
  );

  useEffect(() => {
    if (blocklyWorkspace && runRulesOnInitialInputs) {
      applyRulesToProductDefinitionInputs(currentProductDefinition.inputs);
      setRunRulesOnInitialInputs(false);
    }
  }, [
    applyRulesToProductDefinitionInputs,
    blocklyWorkspace,
    currentProductDefinition.inputs,
    replaceAllCurrentProductDefinitionInputs,
    runRulesOnInitialInputs,
  ]);

  // Base Model gets values from the Form Preview
  const handleSetModelValues = async (): Promise<void> => {
    try {
      const upsertedProductDefinition = await updateModelAndProductDefinitionInputs(currentProductDefinition);

      // update the current product definition in case user clicked the Set Model Values and the product definition
      // hasn't been saved before, this avoids double creation during the next click
      if (currentProductDefinition.id !== upsertedProductDefinition.id) {
        setCurrentProductDefinition(upsertedProductDefinition);
      }

      showNotification({
        message: text.inventorModelSuccessfullyUpdated,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    } catch (err: unknown) {
      if (err instanceof CreateProductDefinitionError) {
        showNotification({
          message: err.message,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      } else {
        showNotification({
          message: text.inventorModelUpdateFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }

      logError(err);
    }
  };

  const handleInputUpdate = (payload: DCInput): Promise<void> => {
    setUpdateFormEnabled(false);
    const updatedInputs = currentProductDefinition.inputs.reduce(
      (updatedFormData: ProductDefinitionInput[], inputRow: ProductDefinitionInput): ProductDefinitionInput[] => {
        if (inputRow.name === payload.name && inputRow.type !== InputType.IPROPERTY) {
          return [
            ...updatedFormData,
            {
              ...inputRow,
              value: payload.value,
            } as ProductDefinitionInputParameter,
          ];
        }
        return [...updatedFormData, inputRow];
      },
      [],
    );
    return applyRulesToProductDefinitionInputs(updatedInputs);
  };

  const resetToDefaults = (parameterDefaults: ProductDefinition['parametersDefaults']) => {
    if (!parameterDefaults) {
      return;
    }

    const parameterDefaultsMap = new Map(
      parameterDefaults.map((parameterDefault) => [parameterDefault.name, parameterDefault.value]),
    );

    const updatedInputsWithDefaults = currentProductDefinition.inputs.map((input): ProductDefinitionInput => {
      const defaultValue = parameterDefaultsMap.get(input.name);

      if (isUndefined(defaultValue)) {
        return input;
      }

      if (isDynamicContentIProperty(input) || isTextInput(input)) {
        return {
          ...input,
          value: String(defaultValue),
        };
      }
      if (isBooleanInput(input)) {
        return {
          ...input,
          value: Boolean(defaultValue),
        };
      }

      if (isNumericInput(input)) {
        return {
          ...input,
          value: Number(defaultValue),
        };
      }

      return input;
    });

    applyRulesToProductDefinitionInputs(updatedInputsWithDefaults);
  };

  // Form Preview gets the values from the Product Definition Defaults
  const handleResetToDefaults = () => {
    resetToDefaults(currentProductDefinition.parametersDefaults);
  };

  // Product Definition Defaults AND Form Preview get values from the Base Model
  const handleGetModelValues = async () => {
    const fullPath = createFullPath(currentProductDefinition.topLevelFolder, currentProductDefinition.assembly);

    const inventorData = await getPartOrAssemblyProperties(fullPath);

    const newDefaults = setCurrentProductDefinitionParametersDefaultsByInventorParameters(inventorData.parameters);

    resetToDefaults(newDefaults);
  };

  // Form Preview gets values processed by the Code Blocks
  const handleUpdateForm = (): Promise<void> => {
    setUpdateFormEnabled(false);
    return applyRulesToProductDefinitionInputs(currentProductDefinition.inputs);
  };

  return {
    handleSetModelValues,
    handleResetToDefaults,
    handleGetModelValues,
    handleInputUpdate,
    handleUpdateForm,
    inputsError,
    currentFormRules,
    isFormLoading: runRulesOnInitialInputs,
  };
};
