import Visibility from '@mui/icons-material/Visibility';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';

export const StyledTable = styled(Table)`
  margin-top: ${({ theme }) => `${theme.var.marginBase * 2}px`};
`;

export const Drawing2DPreviewWrapper = styled('div')`
  height: ${({ theme }) => `${theme.var.drawingsSize}px`};
`;

export const Drawing2DPreview = styled('img')`
  max-height: ${({ theme }) => `${theme.var.drawingsSize}px`};
  margin-top: ${({ theme }) => `${theme.var.marginBase}px`};
`;

export const Drawing2DEmptyStateWrapper = styled(Paper)`
  max-width: 300px;
  padding: 0;
`;

export const RedirectLink = styled(Link)<{ component?: string }>`
  display: inline-flex;
`;

export const DrawingThumbnailVisibleIcon = styled(Visibility)`
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.primary.autodeskBlue[500]};
  margin-left: ${({ theme }) => `${theme.var.marginBase / 2}px`};
`;

export const DrawingPathText = styled('span')`
  margin-left: ${({ theme }) => `${theme.var.marginBase / 2}px`};
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
  word-break: break-all;
  white-space: normal;
`;
