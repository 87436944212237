import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import text from 'inventor.text.json';
import { StyledTable } from './Drawing2D.styles';

interface SettingsTableProps {
  excludeIntellectualProperty: boolean;
}

const SettingsTable = ({ excludeIntellectualProperty }: SettingsTableProps): JSX.Element => (
  <StyledTable size="small">
    <TableHead>
      <TableRow>
        <TableCell>{text.drawingSettingsTableHeader}</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      <TableCell>
        <FormControlLabel
          disabled
          control={<Checkbox />}
          checked={excludeIntellectualProperty}
          label={text.drawingSettingsTableExcludeIp}
        />
      </TableCell>
    </TableBody>
  </StyledTable>
);

export default SettingsTable;
