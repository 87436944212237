import { CustomError } from '../customError';
import { ErrorCode } from '../errorCode';

type CauseType = {
  numberOfProductDefinitionsSelected?: number;
  productDefinitionId?: string | number;
};

export class EditProductDefinitionError extends CustomError {
  errorCode = ErrorCode.EditProductDefinitionError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
