import { DCInput, InputType, OutputType, PostVariantInput } from '@adsk/offsite-dc-sdk';
import { GenerateOutputError, getUUID } from 'mid-utils';
import {
  GenerateOutputsResult,
  InventorInput,
  InventorOutput,
  InventorOutputFileInfo,
  inventorOutputTypes,
  UploadContentResult,
} from '../interfaces/inventorAutomation';
import { LogLevel } from '../interfaces/log';
import { ProductDefinition, ProductDefinitionInput, ProductDefinitionOutput } from '../interfaces/productDefinitions';
import { uploadFile, DataCategory } from './cloudStorage';
import { compressFolder, deleteFile } from './filesystem';
import { logToFile, saveToFile } from './tools';
import { generateOutputs, getModelStates } from './inventor';

/**
 *
 * @param projectId
 * @param content
 * @param fileName
 * @param fileExtension
 * @param category
 * @returns a promise that resolves with the uploaded content result.
 */
export const uploadContentAsFile = async (
  projectId: string,
  content: string,
  fileName: string,
  fileExtension: string,
  category: DataCategory,
): Promise<UploadContentResult> => {
  const filePath = await saveToFile(content, fileName, fileExtension);
  const objectKey = await uploadFile(projectId, filePath, category, 'application/json');
  return { filePath, objectKey };
};

/**
 *
 * @param productDefinition
 * @returns a promise that resolves with the Output Files generated.
 */
export const getGeneratedOutputFiles = async (productDefinition: ProductDefinition): Promise<InventorOutputFileInfo[]> => {
  // generate the output files through local Inventor
  const generateOutputsResult = await generateOutputFiles(productDefinition);
  if (!generateOutputsResult.success) {
    logToFile(`Failed to generate output files from: ${JSON.stringify(productDefinition)}`, LogLevel.Fatal);
    // TODO: need to define what to report in case of a failure
    throw new GenerateOutputError(generateOutputsResult.report, {
      report: generateOutputsResult.report,
    });
  }

  return generateOutputsResult.outputFiles!;
};

/**
 *
 * @param projectId
 * @param topLevelFolder
 * @returns a promise that resolves with the object key of dataset uploaded.
 */
export const getObjectKeyAfterUploadTopLevelFolder = async (projectId: string, topLevelFolder: string): Promise<string> => {
  logToFile('Start upload zip file (dataset) to oss', LogLevel.Info);
  const zipFilePath = await compressFolder(topLevelFolder);
  const datasetObjectKey = await uploadFile(projectId, zipFilePath, DataCategory.Inputs);
  // Delete zipfle
  await deleteFile(zipFilePath);
  logToFile(`Upload zip file (dataset) successfully ${datasetObjectKey}`, LogLevel.Info);

  return datasetObjectKey;
};

/**
 *
 * @param projectId
 * @param thumbnailFilePath
 * @returns a promise that resolves with the object key of thumbnail after upload it.
 */
export const getObjectKeyAfterUploalThumbnail = async (projectId: string, thumbnailFilePath: string): Promise<string> => {
  logToFile('Start upload thumbnail to oss', LogLevel.Info);
  const thumbnailObjectKey = await uploadFile(projectId, thumbnailFilePath, DataCategory.Outputs, 'image/bmp');
  logToFile(`Upload thumbnail successfully ${thumbnailObjectKey}`, LogLevel.Info);
  return thumbnailObjectKey;
};

/**
 *
 * @param projectId
 * @param contentToUpload
 * @param dataCategory
 * @returns a promise that resolves with the uploaded content result.
 */
export const getUploadContentResult = async (
  projectId: string,
  contentToUpload: Object,
  dataCategory: DataCategory,
): Promise<UploadContentResult> => {
  logToFile(`Start upload ${dataCategory} to oss`, LogLevel.Info);
  const uploadedContentResult = await uploadContentAsFile(
    projectId,
    JSON.stringify(contentToUpload),
    getUUID(),
    'json',
    dataCategory,
  );

  logToFile(`Upload ${dataCategory} successfully ${uploadedContentResult.objectKey}`, LogLevel.Info);
  return uploadedContentResult;
};

export const dynamicContentInputsToPostVariantInputs = (dcInputs: DCInput[]): PostVariantInput[] =>
  dcInputs.map((dcInput) => ({ name: dcInput.name, value: dcInput.value! }));

export const truncateDecimals = (value: number): number => {
  const truncateLength = 4;
  let truncatedValue = value;
  // toString removes trailing zeroes (requirement 1)
  const numberParts = value.toString().split('.');

  // value is a float number
  if (numberParts.length === 2) {
    if (numberParts[1].length > truncateLength) {
      // truncate to 4 decimal places (don't round) (requirement 2)
      truncatedValue = parseFloat(`${numberParts[0]}.${numberParts[1].slice(0, truncateLength)}`);
    }
  }

  return truncatedValue;
};

/**
 *
 * @param productDefinitionInputs
 * @returns transform product definition inputs into Inventor inputs
 */
export const productDefinitionInputsToInventorInputs = (
  productDefinitionInputs: ProductDefinitionInput[],
): InventorInput[] =>
  productDefinitionInputs.map((productDefinitionInput) => {
    switch (productDefinitionInput.type) {
      case InputType.BOOLEAN:
        return {
          name: productDefinitionInput.name,
          value: productDefinitionInput.value.toString(),
          isProperty: false,
        };
      case InputType.TEXT:
        return {
          name: productDefinitionInput.name,
          value: productDefinitionInput.value,
          isProperty: false,
        };
      case InputType.NUMERIC:
        return {
          name: productDefinitionInput.name,
          value: truncateDecimals(productDefinitionInput.value).toString(),
          isProperty: false,
        };
      case InputType.IPROPERTY:
        return {
          name: productDefinitionInput.name,
          value: productDefinitionInput.value.toString(),
          isProperty: true,
        };
      default: {
        throw new Error(`Unreachable case (${productDefinitionInput.type}) error`);
      }
    }
  });

/**
 *
 * @param productDefinitionOutputs
 * @returns transform product definition outputs into Inventor outputs
 */
export const productDefinitionOutputsToInventorOutputs = (
  productDefinitionOutputs: ProductDefinitionOutput[],
): InventorOutput[] =>
  productDefinitionOutputs
    .filter((productDefinitionOutput) => productDefinitionOutput.type === OutputType.RFA)
    .map((productDefinitionOutput) => ({
      type: inventorOutputTypes.RFA,
      modelStates: productDefinitionOutput.options?.modelStates,
    }));

/**
 *
 * @param productDefinition
 * @returns a promise that resolves with the generate output result containing files generated
 */
export const generateOutputFiles = async (productDefinition: ProductDefinition): Promise<GenerateOutputsResult> => {
  logToFile('Start generateOutputFiles', LogLevel.Info);
  const inventorInputs = productDefinitionInputsToInventorInputs(productDefinition.inputs);
  const inventorOutputs = productDefinitionOutputsToInventorOutputs(productDefinition.outputs);

  // Request a thumbnail image for the primary model state.
  // This will serve as the product thumbnail and match what is displayed in the UI.
  const topFolderPath = productDefinition.topLevelFolder;
  const documentFilePath = `${topFolderPath}${productDefinition.assembly}`;
  const tempModelStates: string[] = [];

  const rfaOutput = inventorOutputs.find((output) => output.type === 'RFA');
  const rfaModelStates = rfaOutput?.modelStates;

  // Use the same modelstates as RFA output to generate the thumbnails
  if (rfaModelStates && rfaModelStates?.length > 0) {
    tempModelStates.push(...rfaModelStates);
  } else {
    const avaialbleModelStates = await getModelStates(documentFilePath);
    tempModelStates.push(avaialbleModelStates[0]);
  }

  inventorOutputs.push({
    type: inventorOutputTypes.THUMBNAIL,
    modelStates: tempModelStates,
  });
  logToFile(`generating inventor outputs: ${JSON.stringify(inventorOutputs)}`, LogLevel.Info);
  const generateOutputsResult = await generateOutputs(topFolderPath, documentFilePath, inventorInputs, inventorOutputs);
  logToFile(`End generateOutputFiles: ${JSON.stringify(generateOutputsResult)}`, LogLevel.Info);
  return generateOutputsResult;
};
