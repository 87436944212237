import { BlocklyState } from 'mid-types';
import { AvailableInputFunctionValues, BlocklyInputParameterDropdownValues } from './BlocklyModule.types';
import {
  blockTypeByFunctionType,
  blockTypeByInputType,
  blocklyBlockOptionsByType,
  blocklyConnectingBlock,
  blocklyDropdown,
  blocklyFunctionsDropdown,
  universalInputBlock,
  valueKey,
  invalidInputSymbol,
} from './constants';
import Blockly, { BlockSvg, FieldDropdown, MenuOption } from 'blockly';
import { InputRule, ProductDefinitionInputParameter } from 'mid-addin-lib';
import { InputType, InputTypes } from '@adsk/offsite-dc-sdk';
import { CodeblocksWorkspaceType } from './types';

type blockValue = string | number | boolean | string[] | number[] | undefined;

const generateUniversalInputsBlock = (
  input: BlocklyInputParameterDropdownValues,
  functionValue: AvailableInputFunctionValues,
  value: blockValue,
): BlocklyState => ({
  type: universalInputBlock,
  extraState: {
    inputsDropdown: JSON.stringify(input),
    functionsDropdown: functionValue,
  },
  inputs: {
    connecting_block: {
      block: generateConnectingBlock(input.type, functionValue, value),
    },
  },
  next: {},
});

const generateConnectingBlock = (
  inputType: InputTypes,
  functionValue: AvailableInputFunctionValues,
  value: blockValue,
): BlocklyState => {
  // if we are setting the 'value' key
  // we check by input type instead of function type
  const blockType = getBlockType(inputType, functionValue);

  switch (blockType) {
    case 'Boolean':
      return {
        type: 'logic_boolean',
        fields: {
          BOOL: value ? 'TRUE' : 'FALSE',
        },
      };
    case 'Number':
      return {
        type: 'math_number',
        fields: {
          NUM: value,
        },
      };
    case 'String':
      return {
        type: 'text',
        fields: {
          TEXT: value,
        },
      };
    case 'Array': {
      if (Array.isArray(value)) {
        const blockList: BlocklyState = {
          type: 'lists_create_with',
          inputs: {},
          extraState: {
            itemCount: value.length,
          },
        };
        if (inputType === InputType.NUMERIC) {
          value?.forEach((optionValue, index) => {
            blockList.inputs[`ADD${index}`] = {
              block: {
                type: 'math_number',
                fields: {
                  NUM: optionValue,
                },
              },
            };
          });
          return blockList;
        }
        if (inputType === InputType.TEXT) {
          value?.forEach((optionValue, index) => {
            blockList.inputs[`ADD${index}`] = {
              block: {
                type: 'text',
                fields: {
                  TEXT: optionValue,
                },
              },
            };
          });
          return blockList;
        }
      }
      return {};
    }
    default: {
      return {};
    }
  }
};

export const getInputWithVariableAsValue = (
  input: BlocklyInputParameterDropdownValues,
  functionValue: AvailableInputFunctionValues,
): BlocklyState => ({
  type: universalInputBlock,
  extraState: {
    inputsDropdown: JSON.stringify(input),
    functionsDropdown: functionValue,
  },
  fields: {
    dropdown: JSON.stringify(input),
    functions_dropdown: functionValue,
  },
  inputs: {
    connecting_block: {
      block: {
        type: 'variables_get',
        id: '0Ycyjt4WEg@N2LKZh)6Z',
        fields: {
          VAR: {
            id: ',mVbT32FNP/1d*J.btEV',
          },
        },
      },
    },
  },
  next: {
    block: {
      type: 'variables_set',
      id: 'i5=sEI]|1~pVWUn.wuiw',
      fields: {
        VAR: {
          id: ',mVbT32FNP/1d*J.btEV',
        },
      },
      inputs: {
        VALUE: {
          block: {
            type: universalInputBlock,
            id: '_Y}{oa{9Wb::qLg.#riW',
            extraState: {
              inputsDropdown: JSON.stringify(input),
              functionsDropdown: functionValue,
            },
            fields: {
              dropdown: JSON.stringify(input),
              functions_dropdown: functionValue,
            },
          },
        },
      },
    },
  },
});

const appendInputDropdownsToBlock = (
  block: BlockSvg,
  newInputsDropdown: FieldDropdown,
  value: AvailableInputFunctionValues,
): void => {
  newInputsDropdown.setValidator((newValue: AvailableInputFunctionValues) => inputDropdownTypeValidator(block, newValue));
  updateInputFieldDropdown(block, newInputsDropdown, blocklyDropdown);
  inputDropdownTypeValidator(block, value);
};

const inputDropdownTypeValidator = (block: BlockSvg, inputValue: AvailableInputFunctionValues): void => {
  const newInputType = getInputType(inputValue);

  // Get the previous attribute value before the input field is changed
  const inputAttribute = block.getField(blocklyFunctionsDropdown);
  const previousInputAttributeValue = inputAttribute?.getValue() || valueKey;

  // Make a new attribute dropdown based on type of input
  // ex: Numeric type will have min, max and increment types
  const newInputAttributDropdownOptions = blocklyBlockOptionsByType[newInputType];
  const newInputAttributeDropdown = new FieldDropdown(() => newInputAttributDropdownOptions);

  const currentAttributes = newInputAttributDropdownOptions.map(([attributeKey]) => attributeKey);
  // If previous attribute is part of current attributes list
  // Then set the previous attribute in the new attribute dropdown, so when the input
  // is changed, attribute will remain the same.
  const usePreviousInputAttribute = currentAttributes.includes(previousInputAttributeValue);
  if (usePreviousInputAttribute) {
    newInputAttributeDropdown.setValue(previousInputAttributeValue);
  }
  const currentInputAttribute = usePreviousInputAttribute ? previousInputAttributeValue : valueKey;

  const connectingBlock = block.getInput(blocklyConnectingBlock);

  if (connectingBlock) {
    // Attach the validator for input attribute dropdown
    // When input attribute is updated, it will validate type of value currently attached
    // based on attribute
    // ex: min field will require value to be Numeric. If current value is non Numeric,
    // Blockly will pop out the block, indicating value is not compatible with attribute.
    newInputAttributeDropdown.setValidator((newInputAttribute: AvailableInputFunctionValues) => {
      const blockType = getBlockType(newInputType, newInputAttribute);
      connectingBlock.setCheck(blockType);
    });
    const blockType = getBlockType(newInputType, currentInputAttribute);
    connectingBlock.setCheck(blockType);
  } else {
    newInputAttributeDropdown.setValidator((newInputAttribute: AvailableInputFunctionValues) => {
      const blockType = getBlockType(newInputType, newInputAttribute);
      block.outputConnection.setCheck(blockType);
    });
    const blockType = getBlockType(newInputType, currentInputAttribute);
    block.outputConnection.setCheck(blockType);
  }

  updateInputFieldDropdown(block, newInputAttributeDropdown, blocklyFunctionsDropdown);
};

const getBlockType = (inputType: InputTypes, value: AvailableInputFunctionValues) =>
  value === valueKey ? blockTypeByInputType[inputType] : blockTypeByFunctionType[value];

const updateInputFieldDropdown = (block: BlockSvg, newDropdown: FieldDropdown, fieldName: string): void => {
  const input = block.getInput(fieldName);
  const field = block.getField(fieldName);
  if (!input) {
    return;
  }

  if (field) {
    input.removeField(fieldName);
  }
  input.appendField(newDropdown, fieldName);
};
const getInputType = (inputValue: string): InputTypes => JSON.parse(inputValue).type;

interface BlocksExtraState {
  inputsDropdown?: string;
  functionsDropdown?: string;
  itemCount?: number;
}

const getBlocksExtraState = (state: { [key: string]: any }): Array<BlocksExtraState> => {
  let results = [];

  // Check if the current object has an `extraState` property
  if (typeof state === 'object' && Object.hasOwn(state, 'extraState')) {
    results.push(state.extraState);
  }

  for (const key in state) {
    if (typeof state[key] === 'object') {
      // Recursively call the function for nested objects
      results = results.concat(getBlocksExtraState(state[key]));
    }
  }

  return results;
};

const allRulesHaveInputsAdopted = (rules: InputRule[], parameters: ProductDefinitionInputParameter[]): boolean => {
  let allRulesHaveInputsAdopted = true;
  const regex = /parameters\['([^']+)'\]/g;
  // matches all parameters in rules
  const matches = rules[0].code.matchAll(regex);

  for (const match of matches) {
    const rulesInputName = match[1];
    // every parameter in the rule string has to have a corresponding parameter in the parameters array
    allRulesHaveInputsAdopted = parameters.some((parameter) => parameter.name === rulesInputName);
    if (!allRulesHaveInputsAdopted) {
      break;
    }
  }
  return allRulesHaveInputsAdopted;
};

const dropdownHasInvalidInput = (inputs: MenuOption[]): boolean =>
  inputs.some((input) => typeof input[0] === 'string' && input[0].includes(invalidInputSymbol));

const removeInvalidInput = (inputs: MenuOption[]): MenuOption[] =>
  inputs.filter((input) => typeof input[0] === 'string' && !input[0].includes(invalidInputSymbol));

const isCurrentSelectedOptionValid = (selectedOption: string): boolean => !selectedOption.includes(invalidInputSymbol);

const isSelectedInputNotValid = (inputs: MenuOption[], selectedInput: string): boolean =>
  !inputs.find((input) => input[1] === selectedInput);

const handleWorkspaceSwitch = (
  workspace: Blockly.WorkspaceSvg | undefined,
  isWorkspaceHidden: boolean | undefined,
): void => {
  // When the div is hidden, blockly renders with a height of 0, so we need to manually call render
  if (!isWorkspaceHidden && workspace) {
    Blockly.svgResize(workspace);
  } else if (isWorkspaceHidden && workspace) {
    // Hide chaff hides all temporary UI: tooltips, context menus, dropdown selections, etc.
    workspace.hideChaff();
  }
};

const getSelectedWorkspace = (
  enableFormLayout: boolean,
  latestWorkspaceSelected: CodeblocksWorkspaceType | undefined,
): CodeblocksWorkspaceType => {
  if (enableFormLayout) {
    if (latestWorkspaceSelected) {
      return latestWorkspaceSelected;
    }
    return CodeblocksWorkspaceType.FORM;
  }
  return CodeblocksWorkspaceType.INPUT;
};

export {
  generateUniversalInputsBlock,
  appendInputDropdownsToBlock,
  inputDropdownTypeValidator,
  updateInputFieldDropdown,
  getBlocksExtraState,
  allRulesHaveInputsAdopted,
  dropdownHasInvalidInput,
  removeInvalidInput,
  isCurrentSelectedOptionValid,
  isSelectedInputNotValid,
  handleWorkspaceSwitch,
  getSelectedWorkspace,
};
