import Typography from '@mui/material/Typography';
import { EMPTY_STATE_ILLUSTRATION_TYPES, MIDEmptyState, SpinnerForButton } from 'mid-react-common';
import text from '../../../../inventor.text.json';
import { SourceContentPreviewImage, SpinnerWrapper, ThumbnailContainer } from '../SourceContentTab.styles';

interface PreviewThumbnailProps {
  thumbnailInBase64: { name: string; base64: string } | null;
  isThumbnailLoading: boolean;
}

const PreviewThumbnail: React.FC<PreviewThumbnailProps> = ({ thumbnailInBase64, isThumbnailLoading }): JSX.Element => (
  <div>
    <Typography variant="h2" gutterBottom>
      {text.sourceContentThumbnail}
    </Typography>
    <ThumbnailContainer>
      {thumbnailInBase64 ? (
        <SourceContentPreviewImage
          src={`data:image/jpeg;base64,${thumbnailInBase64!.base64}`}
          alt={text.previewImageAltText}
        />
      ) : (
        <>
          {isThumbnailLoading ? (
            <SpinnerWrapper>
              <SpinnerForButton />
            </SpinnerWrapper>
          ) : (
            <MIDEmptyState
              title={text.notificationThumbnailFailed}
              illustrationType={EMPTY_STATE_ILLUSTRATION_TYPES.NO_RESULTS}
            />
          )}
        </>
      )}
    </ThumbnailContainer>
  </div>
);

export default PreviewThumbnail;
