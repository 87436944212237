import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import testIds from 'inventor.testids';
import text from 'inventor.text.json';
import { IconWrapper, Spacing, Text } from 'mid-react-common';
import React, { useContext } from 'react';
import { useProductDefinitionName } from './useProductDefinitionName';
import DataContext from 'context/DataStore/Data.context';

export const TextFieldWrapper = styled(TextField)<{ width?: number }>`
  width: ${({ width, theme }) => (width ? `${width}px` : `${theme.var.wideInputControl}px`)}};
`;

interface ProductDefinitionNameProps {
  initialName: string;
}

const ProductDefinitionName: React.FC<ProductDefinitionNameProps> = ({ initialName }): JSX.Element => {
  const theme = useTheme();

  const { currentProductDefinition } = useContext(DataContext);
  const { productDefinitionNameError, handleProductDefinitionNameChange } = useProductDefinitionName();

  return (
    <>
      <Typography variant="h2" gutterBottom>
        {text.productDefinitionLabel}
      </Typography>
      <Text>
        <IconWrapper>
          <VisibilityOffIcon />
        </IconWrapper>
        {text.sourceContentProductDefinitionInfo}
      </Text>
      <Spacing />
      {(currentProductDefinition.name || initialName) && (
        <TextFieldWrapper
          size="small"
          error={!!productDefinitionNameError}
          helperText={productDefinitionNameError}
          width={theme.var.wideInputControl * 1.5}
          label={text.productDefinitionName}
          inputProps={{ 'data-testid': testIds.productDefinitionNameInput }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <EditIcon />
              </InputAdornment>
            ),
          }}
          placeholder={text.productDefinitionNameUntitled}
          disabled={!currentProductDefinition.assembly}
          defaultValue={currentProductDefinition.name || initialName}
          onChange={handleProductDefinitionNameChange}
        />
      )}
    </>
  );
};

export default ProductDefinitionName;
