import { ErrorCode } from '../errorCode';
import { CustomError } from '../customError';

type CauseType = {
  error?: Error;
};
export class CreateProductDefinitionError extends CustomError {
  errorCode = ErrorCode.CreateProductDefinitionError;

  constructor(message: string, cause: CauseType) {
    super(message, cause);
  }
}
