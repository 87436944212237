import {
  ProductDefinition,
  ProductDefinitionInput,
  ProductDefinitionInputParameter,
  ProductDefinitionIProperty,
  ProductDefinitionOutput,
  InputRule,
  SerializedBlocklyWorkspaceState,
} from 'mid-addin-lib';

import { DrawingThumbnail, MetaInfo, MetaInfoPath } from 'mid-types';
import { OutputTypes } from '@adsk/offsite-dc-sdk';

export enum CurrentProductDefinitionActionTypes {
  SET_PRODUCT_DEFINITION = 'SET_PRODUCT_DEFINITION',
  SET_NAME = 'SET_NAME',
  SET_SOURCE_MODEL = 'SET_SOURCE_MODEL',
  SET_PRODUCT_THUMBNAIL = 'SET_PRODUCT_THUMBNAIL',
  SET_PUBLISH_LOCATION = 'SET_PUBLISH_LOCATION',
  SET_RULE = 'SET_RULE',
  SET_CODE_BLOCKS_WORKSPACE = 'SET_CODE_BLOCKS_WORKSPACE',
  SET_FORM_CODE_BLOCKS_WORKSPACE = 'SET_FORM_CODE_BLOCKS_WORKSPACE',
  SET_DRAWING_THUMBNAILS = 'SET_DRAWING_THUMBNAILS',
  SET_PARAMETERS_DEFAULTS = 'SET_PARAMETERS_DEFAULTS',

  REPLACE_ALL_INPUTS = 'REPLACE_ALL_INPUTS',
  ADD_OUTPUTS = 'ADD_OUTPUTS',

  UPDATE_PARAMETER = 'UPDATE_PARAMETER',
  UPDATE_IPROPERTY = 'UPDATE_IPROPERTY',
  UPDATE_TABLE = 'UPDATE_TABLE',
  ADD_OUTPUT = 'ADD_OUTPUT',
  ADD_OUTPUT_OPTIONS = 'ADD_OUTPUT_OPTIONS',
  REMOVE_OUTPUT = 'REMOVE_OUTPUT',

  RESET_PRODUCT_DEFINITION = 'RESET_PRODUCT_DEFINITION',
  DELETE_TABLE = 'DELETE_TABLE',
  DELETE_DRAWING_OUTPUT = 'DELETE_DRAWING_OUTPUT',
  DELETE_DRAWING_OUTPUTS = 'DELETE_DRAWING_OUTPUTS',
}

type SetCurrentProductDefinition = {
  type: CurrentProductDefinitionActionTypes.SET_PRODUCT_DEFINITION;
  payload: {
    productDefinition: ProductDefinition;
  };
};

type ResetCurrentProductDefinition = {
  type: CurrentProductDefinitionActionTypes.RESET_PRODUCT_DEFINITION;
};

type SetCurrentProductDefinitionName = {
  type: CurrentProductDefinitionActionTypes.SET_NAME;
  payload: {
    newProductDefinitionName: string;
  };
};

type SetCurrentProductDefinitionSourceModel = {
  type: CurrentProductDefinitionActionTypes.SET_SOURCE_MODEL;
  payload: {
    topLevelFolder?: string;
    inventorProject?: string;
    assembly?: string;
  };
};

type SetCurrentProductDefinitionThumbnail = {
  type: CurrentProductDefinitionActionTypes.SET_PRODUCT_THUMBNAIL;
  payload: {
    thumbnail: string;
  };
};

type SetCurrentProductDefinitionPublishLocation = {
  type: CurrentProductDefinitionActionTypes.SET_PUBLISH_LOCATION;
  payload: {
    account: MetaInfo;
    project: MetaInfo;
    folder: MetaInfoPath;
  };
};

type SetCurrentProductDefinitionRule = {
  type: CurrentProductDefinitionActionTypes.SET_RULE;
  payload: {
    rule: InputRule;
  };
};

type SetCurrentProductDefinitionCodeBlocksWorkspace = {
  type: CurrentProductDefinitionActionTypes.SET_CODE_BLOCKS_WORKSPACE;
  payload: {
    workspaceSerialized: SerializedBlocklyWorkspaceState | undefined;
  };
};

type SetCurrentFormCodeBlocksWorkspace = {
  type: CurrentProductDefinitionActionTypes.SET_FORM_CODE_BLOCKS_WORKSPACE;
  payload: {
    workspaceSerialized: SerializedBlocklyWorkspaceState | undefined;
  };
};

type SetCurrentProductDefinitionParametersDefaults = {
  type: CurrentProductDefinitionActionTypes.SET_PARAMETERS_DEFAULTS;
  payload: {
    parametersDefaults: ProductDefinition['parametersDefaults'];
  };
};

type SetCurrentProductDefinitionDrawingThumbnails = {
  type: CurrentProductDefinitionActionTypes.SET_DRAWING_THUMBNAILS;
  payload: {
    drawingThumbnails: DrawingThumbnail[];
  };
};

type ReplaceAllProductDefinitionInputs = {
  type: CurrentProductDefinitionActionTypes.REPLACE_ALL_INPUTS;
  payload: {
    newInputs: ProductDefinitionInput[];
  };
};

type UpdateCurrentProductDefinitionParameter = {
  type: CurrentProductDefinitionActionTypes.UPDATE_PARAMETER;
  payload: {
    parameterToUpdate: ProductDefinitionInputParameter;
    updatedValue: { [key: string]: string | boolean | number };
  };
};

type UpdateCurrentProductDefinitionIProperty = {
  type: CurrentProductDefinitionActionTypes.UPDATE_IPROPERTY;
  payload: {
    iPropertyToUpdate: ProductDefinitionIProperty;
    updatedValue: { [key: string]: string };
  };
};

type DeleteCurrentProductDefinitionTable = {
  type: CurrentProductDefinitionActionTypes.DELETE_TABLE;
};

type AddCurrentProductDefinitionOutputOptions = {
  type: CurrentProductDefinitionActionTypes.ADD_OUTPUT_OPTIONS;
  payload: {
    outputType: OutputTypes;
    outputOptions: ProductDefinitionOutput['options'];
  };
};
type RemoveCurrentProductDefinitionOutput = {
  type: CurrentProductDefinitionActionTypes.REMOVE_OUTPUT;
  payload: {
    outputType: OutputTypes;
  };
};

type AddCurrentProductDefinitionOutputs = {
  type: CurrentProductDefinitionActionTypes.ADD_OUTPUTS;
  payload: {
    outputs: ProductDefinitionOutput[];
  };
};

type DeleteCurrentProductDefinitionDrawingOutput = {
  type: CurrentProductDefinitionActionTypes.DELETE_DRAWING_OUTPUT;
  payload: {
    outputType: OutputTypes;
    drawingTemplatePath: string;
  };
};

type DeleteCurrentProductDefinitionDrawingOutputs = {
  type: CurrentProductDefinitionActionTypes.DELETE_DRAWING_OUTPUTS;
  payload: {
    drawingTemplatePaths: string[];
  };
};

export type CurrentProductDefinitionActions =
  | SetCurrentProductDefinition
  | ResetCurrentProductDefinition
  | SetCurrentProductDefinitionName
  | SetCurrentProductDefinitionSourceModel
  | SetCurrentProductDefinitionThumbnail
  | SetCurrentProductDefinitionPublishLocation
  | SetCurrentProductDefinitionRule
  | SetCurrentProductDefinitionCodeBlocksWorkspace
  | SetCurrentFormCodeBlocksWorkspace
  | SetCurrentProductDefinitionDrawingThumbnails
  | SetCurrentProductDefinitionParametersDefaults
  | ReplaceAllProductDefinitionInputs
  | AddCurrentProductDefinitionOutputs
  | UpdateCurrentProductDefinitionParameter
  | UpdateCurrentProductDefinitionIProperty
  | AddCurrentProductDefinitionOutputOptions
  | RemoveCurrentProductDefinitionOutput
  | DeleteCurrentProductDefinitionTable
  | DeleteCurrentProductDefinitionDrawingOutput
  | DeleteCurrentProductDefinitionDrawingOutputs;
