import { useSortable } from '@dnd-kit/sortable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { ListItemIconWrapper, SidebarListItem } from './InputsSidebar.styles';
import { useTheme } from '@mui/material/styles';

export interface InputProps {
  id: string;
  sortingId: string;
  name: string;
  renderIcon: () => JSX.Element;
}

export const Input: React.FC<InputProps> = ({ id, sortingId, name, renderIcon }) => {
  const theme = useTheme();
  const { listeners, attributes } = useSortable({ id: sortingId });

  return (
    <SidebarListItem data-testid={id} id={sortingId}>
      <ListItemIconWrapper
        {...attributes}
        {...listeners}
        sorting={false}
        opacity={0}
        paddingright={theme.var.marginBase}
        data-testid="dragHandle"
      >
        <DragIndicatorIcon />
      </ListItemIconWrapper>
      {renderIcon()}
      <ListItemText primary={name} />
    </SidebarListItem>
  );
};
