import { useContext, useMemo } from 'react';
import DataContext from 'context/DataStore/Data.context';
import { getFileType } from '../Drawing2D.utils';
import { DrawingThumbnail, drawingOutputTypes, drawingTemplateFileTypes, PDF_FILE_TYPE } from 'mid-types';
import { OutputTypes } from '@adsk/offsite-dc-sdk';

interface SelectedDrawing {
  outputType: string;
  drawingPath: string;
  available: boolean;
}

interface useDrawingExportRowsState {
  drawingOutputList: SelectedDrawing[];
  handleDrawingOutputChange: (name: string, outputType: OutputTypes, checked: boolean) => void;
}

export const useDrawingExportRows = (): useDrawingExportRowsState => {
  const { currentProductDefinition, addCurrentProductDefinitionOutputs, deleteCurrentProductDefinitionDrawingOutput } =
    useContext(DataContext);
  const { drawingThumbnails } = currentProductDefinition;

  const drawingOutputList: SelectedDrawing[] = useMemo(() => {
    const selectedDrawings: DrawingThumbnail[] = drawingThumbnails?.filter((drawing) => drawing.checked) || [];

    return selectedDrawings.reduce<SelectedDrawing[]>((drawingList, selectedDrawing) => {
      const templateType = getFileType(selectedDrawing.relativePath);

      if (!templateType || !drawingOutputTypes[templateType] || !drawingTemplateFileTypes.includes(templateType)) {
        return drawingList;
      }

      const isDrawingOutputAvailable = !!currentProductDefinition.outputs.filter(
        (output) =>
          output.type === drawingOutputTypes[templateType] &&
          output?.options?.drawingTemplatePath === selectedDrawing.relativePath,
      ).length;

      const isPdfDrawingOutputAvailable = !!currentProductDefinition.outputs.filter(
        (output) =>
          output.type === drawingOutputTypes[PDF_FILE_TYPE] &&
          output?.options?.drawingTemplatePath === selectedDrawing.relativePath,
      ).length;

      return [
        ...drawingList,
        {
          outputType: drawingOutputTypes[templateType],
          drawingPath: selectedDrawing.relativePath,
          available: isDrawingOutputAvailable,
        },
        {
          outputType: drawingOutputTypes[PDF_FILE_TYPE],
          drawingPath: selectedDrawing.relativePath,
          available: isPdfDrawingOutputAvailable,
        },
      ];
    }, []);
  }, [drawingThumbnails, currentProductDefinition.outputs]);

  const handleDrawingOutputChange = (drawingPath: string, outputType: OutputTypes, checked: boolean) => {
    if (checked) {
      addCurrentProductDefinitionOutputs([{ type: outputType, options: { drawingTemplatePath: drawingPath } }]);
    } else {
      deleteCurrentProductDefinitionDrawingOutput(outputType, drawingPath);
    }
  };

  return {
    drawingOutputList,
    handleDrawingOutputChange,
  };
};
