import React from 'react';
import text from 'inventor.text.json';
import { OutputsSummaryTableData } from '../publishing.utils';
import Typography from '@mui/material/Typography';

import {
  OutputsSummaryTableCell,
  OutputsSummaryTableContainer,
  OutputsSummaryTableHeader,
  OutputsSummaryTableHeaderCell,
  OutputsSummaryTableHeaderRow,
  OutputsSummaryTableRow,
} from './OutputsSummaryTable.styles';

export interface OutputsSummaryTableProps {
  data: OutputsSummaryTableData[];
}

const OutputsSummaryTable: React.FC<OutputsSummaryTableProps> = ({ data }): JSX.Element => (
  <OutputsSummaryTableContainer>
    <OutputsSummaryTableHeader>
      <OutputsSummaryTableHeaderRow>
        <OutputsSummaryTableHeaderCell>
          <Typography variant="h2" fontWeight="bolder">
            {text.publishOutputType}
          </Typography>
        </OutputsSummaryTableHeaderCell>
        <OutputsSummaryTableHeaderCell>
          <Typography variant="h2" fontWeight="bolder">
            {text.publishFileTypes}
          </Typography>
        </OutputsSummaryTableHeaderCell>
        <OutputsSummaryTableHeaderCell>
          <Typography variant="h2" fontWeight="bolder">
            {text.publishRepresentations}
          </Typography>
        </OutputsSummaryTableHeaderCell>
      </OutputsSummaryTableHeaderRow>
    </OutputsSummaryTableHeader>
    <tbody>
      {data.map((dataRow, index) => (
        <OutputsSummaryTableRow key={index}>
          <OutputsSummaryTableCell>
            <Typography variant="h3" fontWeight="bolder">
              {dataRow.outputType}
            </Typography>
          </OutputsSummaryTableCell>
          <OutputsSummaryTableCell>
            <Typography>{dataRow.fileTypes}</Typography>
          </OutputsSummaryTableCell>
          <OutputsSummaryTableCell>
            <Typography>{dataRow.representations}</Typography>
          </OutputsSummaryTableCell>
        </OutputsSummaryTableRow>
      ))}
    </tbody>
  </OutputsSummaryTableContainer>
);
export default OutputsSummaryTable;
