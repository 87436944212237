import { InputType } from '@adsk/offsite-dc-sdk';
import { ProductDefinitionInput, ProductDefinitionIProperty, ProductDefinitionInputParameter } from 'mid-addin-lib';

export function isProductDefinitionIProperty(input: ProductDefinitionInput): input is ProductDefinitionIProperty {
  return input.type === InputType.IPROPERTY;
}

export function isNotProductDefinitionIProperty(input: ProductDefinitionInput): input is ProductDefinitionInputParameter {
  return !isProductDefinitionIProperty(input);
}
