import Divider from '@mui/material/Divider';
import { debounce } from 'lodash';
import { useTableLoadingState } from 'mid-react-common';
import React, { useEffect, useState } from 'react';
import text from '../../../../inventor.text.json';
import InputsHeader from './InputsHeader';
import {
  AdoptedInputsContainer,
  InputsSelectionContainer,
  InputsSelectionTable,
  TableContainer,
} from './InputsSelection.styles';
import { InputsSidebar } from './InputsSidebar/InputsSidebar';
import { SidebarLabel } from './InputsSidebar/InputsSidebar.styles';
import { getIPropertiesTableColumns, getParameterTableColumns } from './constants';
import { useInputsHeader } from './useInputsHeader';
import useInputsSelection from './useInputsSelection';
import { useInputsTable } from './useInputsTable';
import { useTransformToTableIds } from './useTransformToTableIds';

const InputsSelection: React.FC = (): JSX.Element => {
  const {
    inventorData,
    productDefinitionInputs,
    selectedParameters,
    selectedIProperties,
    setSelectedParameters,
    setSelectedIProperties,
    loading,
  } = useInputsSelection();

  const {
    buttonGroupValue,
    handleOnParameterOriPropertyButtonChange,
    currentFilter,
    setCurrentFilter,
    filterItems,
    isParameter,
  } = useInputsHeader();

  const [parameterColumns, setParameterColumns] = useState(getParameterTableColumns());
  const [iPropertyColumns, setIPropertyColumns] = useState(getIPropertiesTableColumns());

  useEffect(() => {
    const windowResizeCallback = () => {
      setParameterColumns(getParameterTableColumns());
      setIPropertyColumns(getIPropertiesTableColumns());
    };
    const debouncedWindowResizeCallback = debounce(windowResizeCallback, 300);

    window.addEventListener('resize', debouncedWindowResizeCallback);

    return () => window.removeEventListener('resize', debouncedWindowResizeCallback);
  });

  const selectedIds = useTransformToTableIds(isParameter ? selectedParameters : selectedIProperties);

  const totalSelected = selectedParameters.length + selectedIProperties.length;

  const { tableData, onInputSelection, filteredData, renderEmptyState } = useInputsTable({
    initialTableData: inventorData ? (isParameter ? inventorData.parameters : inventorData.iProperties) : null,
    currentFilter,
    isParameter,
    setSelectedInputIds: isParameter ? setSelectedParameters : setSelectedIProperties,
  });

  return (
    <InputsSelectionContainer>
      <TableContainer>
        <InputsHeader
          buttonGroupValue={buttonGroupValue}
          handleOnParameterOriPropertyButtonChange={handleOnParameterOriPropertyButtonChange}
          filterItems={filterItems}
          currentFilter={currentFilter}
          isParameter={isParameter}
          setCurrentFilter={setCurrentFilter}
          totalSelected={totalSelected}
        />

        <InputsSelectionTable
          checkboxSelection
          density="compact"
          disableColumnFilter
          hideFooterPagination
          initialState={isParameter ? { columns: { columnVisibilityModel: { isKey: false } } } : undefined}
          columns={isParameter ? parameterColumns : iPropertyColumns}
          rows={tableData}
          getRowId={(row) => (isParameter ? row['name'] : row['id'])}
          selectionModel={selectedIds}
          onSelectionModelChange={onInputSelection}
          filterModel={filteredData}
          loading={loading}
          components={{
            NoRowsOverlay: renderEmptyState,
            LoadingOverlay: useTableLoadingState,
          }}
        />
      </TableContainer>
      <Divider orientation="vertical" flexItem />
      <AdoptedInputsContainer className="mid-bg-shadow">
        <SidebarLabel variant="h2">{`${text.inputsAdopted} (${productDefinitionInputs.length})`}</SidebarLabel>
        <InputsSidebar selectedInputs={productDefinitionInputs} />
      </AdoptedInputsContainer>
    </InputsSelectionContainer>
  );
};

export default InputsSelection;
