import {
  DCInputBooleanType,
  DCInputIPropertyType,
  DCInputMultiValueNumericType,
  DCInputMultiValueTextType,
  DCInputNumericType,
  DCInputTextType,
  DynamicContent,
  OutputTypes,
} from '@adsk/offsite-dc-sdk';
import { DCInputUIExtension, DrawingThumbnail } from 'mid-types';
import { MetaInfo, MetaInfoPath } from 'mid-types';

export interface SerializedBlocklyWorkspaceState {
  [key: string]: any;
}

export type ProductDefinitionParameterDefaultValue =
  | DCInputTextType['value']
  | DCInputNumericType['value']
  | DCInputBooleanType['value']
  | undefined;

export type ProductDefinitionParameterDefault = {
  name: string;
  value: ProductDefinitionParameterDefaultValue;
};

/**
 * Interface definition for product definition.
 */
export interface ProductDefinition {
  id?: string;
  name: string;
  releaseName: string;
  lastUpdated: number;
  account: MetaInfo;
  project: MetaInfo;
  folder: MetaInfoPath;
  topLevelFolder: string;
  inventorProject: string;
  assembly: string;
  thumbnail: string;
  drawingThumbnails?: DrawingThumbnail[];
  inputs: ProductDefinitionInput[];
  parametersDefaults?: ProductDefinitionParameterDefault[];
  rules: InputRule[];
  codeBlocksWorkspace?: SerializedBlocklyWorkspaceState;
  formCodeBlocksWorkspace?: SerializedBlocklyWorkspaceState;
  outputs: ProductDefinitionOutput[];
  isArchived?: boolean;
  latestContentId?: string;
}

export interface ProductDefinitionReducerMap extends Omit<ProductDefinition, 'inputs'> {
  inputs: Map<string, ProductDefinitionInput>;
}

export interface ProductDefinitionInputProperties {
  iProperties: ProductDefinitionIProperty[];
  parameters: ProductDefinitionInputParameter[];
}

export type ProductDefinitionInputParameter =
  | DCInputUIExtension<DCInputBooleanType>
  | DCInputUIExtension<DCInputTextType>
  | DCInputUIExtension<DCInputNumericType>
  | DCInputUIExtension<DCInputMultiValueTextType>
  | DCInputUIExtension<DCInputMultiValueNumericType>;

export type ProductDefinitionInput = DCInputUIExtension<ProductDefinitionIProperty> | ProductDefinitionInputParameter;

export enum PublishStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  COMPLETE = 'complete',
  FAILURE = 'failed',
}

// Extend ProductDefinition Param and IProperty interfaces.
// Add extra properties not required in ProductDefinition here.
export interface ProductDefinitionIProperty extends DCInputIPropertyType {
  id: string;
}

export interface InputRule {
  key: string;
  code: string;
  label?: string;
  errorMessage?: string;
}

export interface ProductDefinitionOutput {
  type: OutputTypes;
  options?: {
    modelStates?: string[];
    category?: string;
    family?: string;
    drawingTemplatePath?: string;
  };
}

export interface ProductDefinitionPublishResult {
  status: PublishStatus;
  errorMessage?: string;
  publishedProduct?: DynamicContent;
}
