import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { styled } from '@mui/material/styles';

export const ListItemIconWrapper = styled(ListItemIcon, {
  target: 'ListItemIconWrapper',
  shouldForwardProp: (props) => props !== 'sorting',
})<{
  sorting?: boolean;
  opacity?: number;
  paddingright?: number;
}>`
  padding-right: ${({ paddingright }) => paddingright || 0}px;
  min-width: 0;
  cursor: ${({ sorting }) => (sorting ? 'grabbing' : 'grab')};
  ${({ opacity }) => (opacity || opacity === 0) && `opacity: ${opacity};`}
`;

export const SidebarListItem = styled(ListItem)`
  &:hover {
    ${ListItemIconWrapper} {
      opacity: 1;
    }
  }
`;

export const SidebarList = styled(List)<{ height?: number }>`
  overflow: auto;
  padding: 0;
  max-height: calc(100% - ${({ theme }) => `${theme.var.adoptedInputsLabelHeight}px`});
`;

export const SidebarLabel = styled(Typography)`
  display: block;
  margin: ${({ theme }) => `${theme.var.marginBase * 2.5}px ${theme.var.marginBase * 2}px`};
`;
