import Divider from '@mui/material/Divider';
import { isUndefined } from 'lodash';
import { useContext } from 'react';
import DataContext from '../../context/DataStore/Data.context';
import inventorTestIds from '../../inventor.testids';
import { BlocklyPlaceholder, BlocklyWrapper } from './BlocklyModule.styles';
import { ProductFormPreview } from './ProductFormPreview/ProductFormPreview';
import useBlocklyModule from './hooks/useBlocklyModule';
import useGenerateBlocklyState from './hooks/useGenerateBlocklyState';
import FormCodeblocks from './FormCodeblocks/FormCodeblocks';
import { CodeblocksWorkspaceType } from './types';
import WorkspaceSelector from './Components/WorkspaceSelector';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getSelectedWorkspace } from './utils';

const { blocklyWorkspaceTestId } = inventorTestIds;

const BlocklyModule: React.FC = (): JSX.Element => {
  const { enableFormLayout } = useFlags();
  const {
    currentProductDefinition,
    setCurrentFormCodeBlocksWorkspace,
    setCurrentProductDefinitionRule,
    latestWorkspaceSelected,
    setLatestWorkspaceSelected,
    recentlyAdoptedInputs,
    setRecentlyAdoptedInputs,
  } = useContext(DataContext);
  const selectedWorkspace = getSelectedWorkspace(enableFormLayout, latestWorkspaceSelected);
  const inputState = useGenerateBlocklyState(currentProductDefinition.inputs);
  const { ref, getCode, blocklyWorkspace, setUpdateFormEnabled, updateFormEnabled } = useBlocklyModule({
    initialState: currentProductDefinition.codeBlocksWorkspace || inputState,
    shouldCleanupBlocks: isUndefined(currentProductDefinition.codeBlocksWorkspace),
    hidden: selectedWorkspace !== CodeblocksWorkspaceType.INPUT,
  });

  return (
    <BlocklyWrapper>
      {enableFormLayout && (
        <WorkspaceSelector selectedWorkspace={selectedWorkspace} setSelectedWorkspace={setLatestWorkspaceSelected} />
      )}
      <BlocklyPlaceholder
        ref={ref}
        data-testid={blocklyWorkspaceTestId}
        hidden={selectedWorkspace !== CodeblocksWorkspaceType.INPUT}
      />
      {enableFormLayout && (
        <FormCodeblocks
          hidden={selectedWorkspace !== CodeblocksWorkspaceType.FORM}
          inputs={currentProductDefinition.inputs}
          setFormCodeBlocksWorkspace={setCurrentFormCodeBlocksWorkspace}
          formCodeblocksWorkspace={currentProductDefinition.formCodeBlocksWorkspace}
          setFormCodeBlocksRules={setCurrentProductDefinitionRule}
          recentlyAdoptedInputs={recentlyAdoptedInputs}
          setRecentlyAdoptedInputs={setRecentlyAdoptedInputs}
        />
      )}
      <Divider orientation="vertical" />
      <ProductFormPreview
        getCode={getCode}
        blocklyWorkspace={blocklyWorkspace}
        setUpdateFormEnabled={setUpdateFormEnabled}
        updateFormEnabled={updateFormEnabled}
        selectedWorkspace={selectedWorkspace}
      />
    </BlocklyWrapper>
  );
};

export default BlocklyModule;
