import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import text from 'inventor.text.json';
import { PublishStatus } from 'mid-addin-lib';
import {
  ACCDocSelection,
  FlexContainer,
  LocationContainer,
  ProductFolderBrowser,
  RightButton,
  useACCDocSelection,
} from 'mid-react-common';
import React, { useContext } from 'react';
import { publishTestIds } from 'tests/helpers/dataTestIds';
import DataContext from '../../context/DataStore/Data.context';
import testIds from '../../inventor.testids';
import { DividerWrapper, Wrapper, productFolderBrowserHeight } from './Publishing.styles';
import { PublishingComplete } from './PublishingComplete';
import { PublishingFailed } from './PublishingFailed';
import { PublishingLoading } from './PublishingLoading';
import { usePublishing } from './usePublishing';

export const Publishing: React.FC = (): JSX.Element => {
  const {
    accounts,
    accountsLoading,
    projects,
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  } = useACCDocSelection();
  const {
    products,
    productsLoading,
    productsError,
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeItem,
    isPublishDisabled,
    publishResponse,
    productNameToPublish,
    handleSelectFolder,
    handlePublishClick,
    handleProductNameChange,
    handleProductNameDoubleClick,
    publishFolderPermission,
    productNameErrors,
  } = usePublishing({ selectedAccount, selectedProject });
  const { currentProductDefinition, currentProductDefinitionPublishStatus } = useContext(DataContext);

  const renderPublishStatusComponents = (status: PublishStatus) => {
    switch (status) {
      case PublishStatus.IDLE:
        return (
          <LocationContainer>
            <Typography variant="h2" gutterBottom>
              {text.publishReleaseDetails}
            </Typography>
            <TextField
              label={text.publishProductName}
              helperText={productNameErrors || text.publishReleaseDetailsInformation}
              size="small"
              onChange={handleProductNameChange}
              value={productNameToPublish}
              inputProps={{ 'data-testid': testIds.publishProductName }}
              error={!!productNameErrors}
            />
            <DividerWrapper />
            <Typography variant="h2" gutterBottom>
              {text.publishLocationTitle}
            </Typography>
            {accounts && (
              <>
                <FlexContainer>
                  <ACCDocSelection
                    accounts={accounts}
                    accountsLoading={accountsLoading}
                    projects={projects || []}
                    projectsLoading={projectsLoading}
                    selectedAccount={selectedAccount}
                    selectedProject={selectedProject}
                    projectsDisabled={projectsDisabled}
                    handleSelectAccount={handleSelectAccount}
                    handleSelectProject={handleSelectProject}
                    maxHeight={productFolderBrowserHeight}
                  />
                  <RightButton
                    size="small"
                    variant="contained"
                    disabled={isPublishDisabled}
                    onClick={handlePublishClick}
                    data-testid={publishTestIds.publishButton}
                  >
                    {text.buttonPublish}
                  </RightButton>
                </FlexContainer>
                <ProductFolderBrowser
                  projectId={selectedProject?.id || ''}
                  rootFolders={rootFoldersTreeItems}
                  rootFoldersLoading={rootFoldersLoading}
                  rootFoldersError={rootFoldersError}
                  selectedFolderTreeElement={selectedFolderTreeItem}
                  displayCreateNewFolderButton
                  products={products}
                  productsLoading={productsLoading}
                  productsError={productsError}
                  onFolderClick={handleSelectFolder}
                  folderPermissionFilter={publishFolderPermission}
                  maxHeight={productFolderBrowserHeight}
                  onProductDoubleClick={handleProductNameDoubleClick}
                />
              </>
            )}
          </LocationContainer>
        );
      case PublishStatus.LOADING:
        return (
          <Wrapper>
            <PublishingLoading
              currentProductDefinition={currentProductDefinition}
              publishedProductName={productNameToPublish}
            />
          </Wrapper>
        );
      case PublishStatus.COMPLETE:
        return (
          <Wrapper>
            <PublishingComplete
              currentProductDefinition={currentProductDefinition}
              publishedProductName={productNameToPublish}
              release={publishResponse?.publishedProduct?.release}
            />
          </Wrapper>
        );
      case PublishStatus.FAILURE:
        return (
          <Wrapper>
            <PublishingFailed
              currentProductDefinition={currentProductDefinition}
              publishedProductName={productNameToPublish}
            />
          </Wrapper>
        );
      default:
        <></>;
    }
  };

  return <>{renderPublishStatusComponents(currentProductDefinitionPublishStatus)}</>;
};
