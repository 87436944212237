import DataContext from '../../../../context/DataStore/Data.context';
import { ProductDefinitionOutput } from 'mid-addin-lib';
import { OutputType, OutputTypes } from '@adsk/offsite-dc-sdk';
import { useContext } from 'react';

export type SelectedOutputRepresentationsMap = Map<OutputTypes, string[] | undefined>;
interface UseSelectOutputRepresentationsState {
  selectedOutputRepresentationsMap: SelectedOutputRepresentationsMap;
  handleRepresentationChange: (outputType: OutputTypes, representationName: string, checked: boolean) => void;
}

export const useSelectOutputRepresentations = (): UseSelectOutputRepresentationsState => {
  const { currentProductDefinition, removeCurrentProductDefinitionOutput, addCurrentProductDefinitionOutputOptions } =
    useContext(DataContext);

  const outputTypeToOutputMap: SelectedOutputRepresentationsMap =
    currentProductDefinition.outputs.reduce<SelectedOutputRepresentationsMap>((outputsMap, output) => {
      outputsMap.set(output.type, output?.options?.modelStates);
      return outputsMap;
    }, new Map());

  const handleRepresentationChange = (outputType: OutputTypes, representationName: string, checked: boolean) => {
    const selectedRepresentations: string[] = outputTypeToOutputMap.get(outputType) || [];
    const newRepresentations = checked
      ? [...selectedRepresentations, representationName]
      : selectedRepresentations.filter((item) => item !== representationName);

    const modelStateOutputOptions: ProductDefinitionOutput['options'] = {
      modelStates: newRepresentations,
    };

    if (!checked && !newRepresentations.length) {
      // If there are no model states selected for the RFA type,
      // we do not remove the output, as the user is not
      // allowed to publish without selecting at least one representation RFA
      // we just reset the selected modelStates
      if (outputType === OutputType.RFA) {
        addCurrentProductDefinitionOutputOptions(outputType, { modelStates: [] });
        return;
      }

      removeCurrentProductDefinitionOutput(outputType);
      return;
    }

    if (outputType === OutputType.IAM) {
      // IAM type has no options, no modelState
      addCurrentProductDefinitionOutputOptions(outputType);
    } else {
      addCurrentProductDefinitionOutputOptions(outputType, modelStateOutputOptions);
      if (outputType === OutputType.RFA) {
        // If RFA output has been inserted or updated, we have to do the same to the equivalent THUMBNAIL
        addCurrentProductDefinitionOutputOptions(OutputType.THUMBNAIL, modelStateOutputOptions);
      }
    }
  };

  return {
    selectedOutputRepresentationsMap: outputTypeToOutputMap,
    handleRepresentationChange,
  };
};
