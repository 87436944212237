import DataContext from 'context/DataStore/Data.context';
import { checkAndGenerateThumbnailInBase64, getDrawingFiles, getThumbnailImgPath } from 'mid-addin-lib';
import { DrawingThumbnail } from 'mid-types';
import { NotificationContext } from 'mid-react-common';
import { isFulfilled, isRejected } from 'mid-utils';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import text from '../../../../inventor.text.json';
import { GridSelectionModel } from '@mui/x-data-grid/models';
import { extractRelativeFilePath } from './drawings.utils';

interface UseDrawingsState {
  drawingsLoading: boolean;
  handleDrawingsSelection: (drawingsSelection: GridSelectionModel) => void;
  refetchDrawings: () => void;
}

const useDrawings = (): UseDrawingsState => {
  const {
    setCurrentProductDefinitionDrawingsThumbnails,
    currentProductDefinition,
    areDrawingFilesFetched,
    setAreDrawingFilesFetched,
    deleteCurrentProductDefinitionDrawingOutputs,
  } = useContext(DataContext);
  const { topLevelFolder, drawingThumbnails, outputs } = currentProductDefinition;
  const { logAndShowNotification } = useContext(NotificationContext);

  const [isDrawingMerged, setIsDrawingMerged] = useState<boolean>(false);
  const [drawingsLoading, setDrawingsLoading] = useState<boolean>(false);
  const [fetchedDrawingThumbnails, setFetchedDrawingThumbnails] = useState<DrawingThumbnail[] | undefined>();

  const handleDrawingsSelection = (drawingsSelection: GridSelectionModel): void => {
    const drawingThumbnailsWithSelectionState: DrawingThumbnail[] | undefined = drawingThumbnails?.map(
      (drawingThumbnail) => ({
        ...drawingThumbnail,
        checked: drawingsSelection.includes(drawingThumbnail.relativePath),
      }),
    );
    if (drawingThumbnailsWithSelectionState) {
      setCurrentProductDefinitionDrawingsThumbnails(drawingThumbnailsWithSelectionState);

      const notSelectedDrawings = drawingThumbnailsWithSelectionState
        .map((drawing) => drawing.relativePath)
        .filter((drawing) => !drawingsSelection.includes(drawing));

      deleteCurrentProductDefinitionDrawingOutputs(notSelectedDrawings);
    }
  };

  const generateThumbnail = useCallback(
    async (drawingFile: string): Promise<{ base64: string; relativePath: string | null; checked: boolean }> => {
      const image = await getThumbnailImgPath(drawingFile);
      const { base64 } = await checkAndGenerateThumbnailInBase64(image, topLevelFolder);
      return { base64, relativePath: extractRelativeFilePath(topLevelFolder, drawingFile), checked: false };
    },
    [topLevelFolder],
  );

  const fetchDrawingFiles = useCallback(async (): Promise<void> => {
    try {
      setDrawingsLoading(true);

      const drawingFiles = await getDrawingFiles(topLevelFolder);
      const drawingThumbnailsPromises = drawingFiles.map(generateThumbnail);
      const results = await Promise.allSettled(drawingThumbnailsPromises);

      const successfulDrawingThumbnails = results
        .filter(isFulfilled)
        .map((result) => result.value)
        .filter((drawing): drawing is DrawingThumbnail => !!drawing.relativePath);

      const failedDrawingImages = results.filter(isRejected);
      if (failedDrawingImages.length) {
        logAndShowNotification({ message: text.notificationFetchGenerateDrawingPathFailed });
      }

      setFetchedDrawingThumbnails(successfulDrawingThumbnails);
      setIsDrawingMerged(false);
    } catch {
      logAndShowNotification({ message: text.notificationGetDrawingFilesFailed });
    } finally {
      setAreDrawingFilesFetched(true);
      setDrawingsLoading(false);
    }
  }, [topLevelFolder, generateThumbnail, logAndShowNotification, setAreDrawingFilesFetched]);

  useEffect(() => {
    if (topLevelFolder && !areDrawingFilesFetched) {
      fetchDrawingFiles();
    }
  }, [areDrawingFilesFetched, fetchDrawingFiles, logAndShowNotification, setAreDrawingFilesFetched, topLevelFolder]);

  const drawingOutputPaths = useMemo(
    () =>
      outputs.reduce<string[]>(
        (paths, output) => (output.options?.drawingTemplatePath ? [...paths, output.options.drawingTemplatePath] : paths),
        [],
      ),
    [outputs],
  );

  useEffect(() => {
    if (fetchedDrawingThumbnails?.length && !isDrawingMerged) {
      const mergedDrawingThumbnails = fetchedDrawingThumbnails.map((fetchedDrawingThumbnail) => {
        const equivalentDrawingInProductDefininition = drawingThumbnails?.find(
          (drawingInProductDefininition) =>
            drawingInProductDefininition.relativePath === fetchedDrawingThumbnail.relativePath,
        );

        if (equivalentDrawingInProductDefininition) {
          return equivalentDrawingInProductDefininition;
        }
        return fetchedDrawingThumbnail;
      });

      const drawingThumbnailPaths = mergedDrawingThumbnails.map((drawingThumbnail) => drawingThumbnail.relativePath);
      // If outputs do not exist in drawing thumbnails because of top level folder change or
      // some one remove these drawing from folder, then these outputs need to be removed
      const outputPathsNotIncludedInThumbnails = drawingOutputPaths.filter(
        (outputPath) => !drawingThumbnailPaths.includes(outputPath),
      );
      deleteCurrentProductDefinitionDrawingOutputs(outputPathsNotIncludedInThumbnails);

      setCurrentProductDefinitionDrawingsThumbnails(mergedDrawingThumbnails);
      setIsDrawingMerged(true);
    }
  }, [
    fetchedDrawingThumbnails,
    drawingThumbnails,
    setCurrentProductDefinitionDrawingsThumbnails,
    isDrawingMerged,
    setIsDrawingMerged,
    drawingOutputPaths,
    deleteCurrentProductDefinitionDrawingOutputs,
  ]);

  const refetchDrawings = () => setAreDrawingFilesFetched(false);

  return {
    drawingsLoading,
    handleDrawingsSelection,
    refetchDrawings,
  };
};

export default useDrawings;
