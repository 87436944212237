import React, { useEffect, useContext, useState } from 'react';
import DataContext from '../../../../context/DataStore/Data.context';
import { debounce } from 'lodash';
import { validateProductDefinitionName } from '../../../../utils/productDefinition';
export const productDefinitionNameSaveDebounceTimeout = 500;

type UseProductDefinitionNameState = {
  productDefinitionNameError: string | null;
  handleProductDefinitionNameChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
};

export const useProductDefinitionName = (): UseProductDefinitionNameState => {
  const { currentProductDefinition, setCurrentProductDefinitionName, setIsErronousProductDefinitionName } =
    useContext(DataContext);
  const [localProductDefinitionName, setLocalProductDefinitionName] = useState<string | undefined>(undefined);
  const [productDefinitionNameError, setProductDefinitionNameError] = useState<string | null>(null);

  const debouncedValidation = debounce((newName: string) => {
    setLocalProductDefinitionName(newName);
    setCurrentProductDefinitionName(newName);
  }, productDefinitionNameSaveDebounceTimeout);

  const handleProductDefinitionNameChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    debouncedValidation(newName);
  };

  useEffect(() => {
    if (currentProductDefinition.name) {
      setLocalProductDefinitionName(currentProductDefinition.name);
    }
  }, [currentProductDefinition.name]);

  useEffect(() => {
    async function validateName(newName: string) {
      const validationError = await validateProductDefinitionName(newName, currentProductDefinition.id);
      setProductDefinitionNameError(validationError.cause);
      setIsErronousProductDefinitionName({ error: validationError.error, cause: validationError.cause });
    }
    if (localProductDefinitionName !== undefined) {
      validateName(localProductDefinitionName);
    }
  }, [currentProductDefinition.id, localProductDefinitionName, setIsErronousProductDefinitionName]);

  return {
    productDefinitionNameError,
    handleProductDefinitionNameChange,
  };
};
