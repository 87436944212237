import { closestCorners, DndContext, DragOverlay, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { ListItemText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ProductDefinitionInput } from 'mid-addin-lib';
import React from 'react';
import { ListItemIconWrapper, SidebarList, SidebarListItem } from './InputsSidebar.styles';
import { SortableInput } from './SortableInput';
import { useInputsSidebar } from './useInputsSidebar';
import { isProductDefinitionIProperty } from 'utils/typeGuards';

export interface InputsSidebarProps {
  selectedInputs: ProductDefinitionInput[];
}

export const InputsSidebar: React.FC<InputsSidebarProps> = ({ selectedInputs }): JSX.Element => {
  const theme = useTheme();
  const { currentDraggingInput, handleDragStart, handleInputsDragEnd } = useInputsSidebar({
    selectedInputs,
  });

  const sensors = useSensors(useSensor(MouseSensor));

  return (
    <SidebarList data-testid="sidebarMenu">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCorners}
        onDragEnd={handleInputsDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={selectedInputs.map((input) => input.name)} strategy={verticalListSortingStrategy}>
          {selectedInputs.map((input: ProductDefinitionInput) => {
            const isIProperty = isProductDefinitionIProperty(input);
            const inputIdentifier = isIProperty ? input.id : input.name;

            return (
              <SortableInput
                id={inputIdentifier}
                sortingId={input.name}
                key={inputIdentifier}
                name={input.name}
                renderIcon={() =>
                  isIProperty ? (
                    <ListItemIconWrapper>
                      <EditTwoToneIcon />
                    </ListItemIconWrapper>
                  ) : (
                    <></>
                  )
                }
              />
            );
          })}
        </SortableContext>
        <DragOverlay>
          {
            <SidebarListItem>
              <ListItemIconWrapper sorting paddingright={theme.var.marginBase}>
                <DragIndicatorIcon />
              </ListItemIconWrapper>
              <ListItemText primary={currentDraggingInput} />
            </SidebarListItem>
          }
        </DragOverlay>
      </DndContext>
    </SidebarList>
  );
};
