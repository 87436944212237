import { GenerateOutputsResult, InventorInput, InventorOutput } from '../interfaces/inventorAutomation';
import { InventorProperties } from '../interfaces/inventorProperties';
import { logError } from 'mid-utils';
import browserApiService from '../services/browserApiService';
import { InventorActiveDocumentInfo } from '../interfaces/inventorActiveDocumentInfo';
import { DialogStatus } from '../interfaces/fileSystem';
import { ExportCodeBlocksOptions, ExportCodeBlocksResult, ExportCodeBlocksStatus } from '../interfaces/inventorCodeBlocks';
import { ImportCodeBlocksOptions, ImportCodeBlocksResult, ImportCodeBlocksStatus } from '../interfaces/inventorCodeBlocks';
import text from '../mid-addin-lib.text.json';

export const getPartOrAssemblyProperties = async (path: string): Promise<InventorProperties> => {
  const result = await browserApiService.getPartOrAssemblyProperties(path);

  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }

  return result.value;
};

export const getModelStates = async (documentPath: string): Promise<string[]> => {
  const modelStatesResult = await browserApiService.getModelStates(documentPath);
  if (modelStatesResult.value === null) {
    throw new Error(`${modelStatesResult.errorMessage}`);
  }
  return modelStatesResult.value;
};

export const generateOutputs = async (
  topFolderPath: string,
  documentPath: string,
  inputs: InventorInput[],
  requestedOutputs: InventorOutput[],
): Promise<GenerateOutputsResult> => {
  const inputsJson = JSON.stringify(inputs);
  const requestedOutputsJson = JSON.stringify(requestedOutputs);
  const generateOutputsResult = await browserApiService.generateOutputs(
    topFolderPath,
    documentPath,
    inputsJson,
    requestedOutputsJson,
  );

  return generateOutputsResult;
};

export const openProductDefinitionDocument = async (documentPath: string, inputs: InventorInput[]): Promise<boolean> => {
  const openStatus = await browserApiService.isDocumentOpenInTheEditor(documentPath);
  if (!openStatus) {
    return false;
  }

  const inputsJson = JSON.stringify(inputs);

  const result = await browserApiService.openProductDefinitionDocument(documentPath, inputsJson);
  if (!result.value) {
    logError(`${result.errorMessage}`);
    return false;
  }
  return true;
};

export const isDocumentOpenInTheEditor = async (documentPath: string): Promise<boolean> => {
  const result = await browserApiService.isDocumentOpenInTheEditor(documentPath);
  if (!result.value) {
    if (result.errorMessage) {
      logError(`${result.errorMessage}`);
    }
    return false;
  }
  return true;
};

export const getEngineVersion = async (): Promise<string> => {
  const result = await browserApiService.getApplicationVersionNumber();
  return result;
};

export const getActiveDocumentInfo = async (): Promise<InventorActiveDocumentInfo> => {
  if (!browserApiService.getActiveDocumentInfo) {
    logError('getActiveDocumentInfo is not implemented in host API');
  }

  const result = await browserApiService.getActiveDocumentInfo();
  if (result.value === null) {
    throw new Error(`${result.errorMessage}`);
  }
  return result.value;
};

export const exportCodeBlocks = async (
  codeBlocks: string,
  options: ExportCodeBlocksOptions,
): Promise<ExportCodeBlocksResult> => {
  if (!browserApiService.saveToFileUsingDialog) {
    const errorMessage = 'saveToFileUsingDialog is not implemented in host API';
    logError(errorMessage);
    return {
      status: ExportCodeBlocksStatus.error,
      message: errorMessage,
    };
  }

  const skipDialog = options.skipDialog ?? false;
  const filter = JSON.stringify([{ name: text.codeBlocksFilter.name, expression: text.codeBlocksFilter.expression }]);
  const fileName = options.fileName;
  const fileLocation = options.fileLocation;
  const title = text.exportCodeBlocksTitle;

  const result = await browserApiService.saveToFileUsingDialog(
    codeBlocks,
    fileName,
    fileLocation,
    filter,
    title,
    skipDialog,
  );
  if (result.value === null) {
    return {
      status: ExportCodeBlocksStatus.error,
      message: result.errorMessage ?? '',
    };
  }

  switch (result.value?.status) {
    case DialogStatus.success:
      return {
        status: ExportCodeBlocksStatus.success,
      };
    case DialogStatus.cancel:
      return {
        status: ExportCodeBlocksStatus.cancel,
      };
    default:
      return {
        status: ExportCodeBlocksStatus.error,
        message: result.errorMessage ?? '',
      };
  }
};

export const importCodeBlocks = async (options: ImportCodeBlocksOptions): Promise<ImportCodeBlocksResult> => {
  if (!browserApiService.readFromFileUsingDialog) {
    const errorMessage = 'readFromFileUsingDialog is not implemented in host API';
    logError(errorMessage);
    return {
      status: ImportCodeBlocksStatus.error,
      message: errorMessage,
    };
  }

  const skipDialog = options.skipDialog ?? false;
  const filter = JSON.stringify([{ name: text.codeBlocksFilter.name, expression: text.codeBlocksFilter.expression }]);
  const fileName = options.fileName ?? '';
  const fileLocation = options.fileLocation;
  const title = text.importCoeBlocksTitle;

  const result = await browserApiService.readFromFileUsingDialog(fileName, fileLocation, filter, title, skipDialog);
  if (result.value === null) {
    return {
      status: ImportCodeBlocksStatus.error,
      message: result.errorMessage ?? '',
    };
  }

  switch (result.value?.status) {
    case DialogStatus.success:
      return {
        status: ImportCodeBlocksStatus.success,
        codeBlocks: result.value?.content,
      };
    case DialogStatus.cancel:
      return {
        status: ImportCodeBlocksStatus.cancel,
      };
    default:
      return {
        status: ImportCodeBlocksStatus.error,
        message: result.errorMessage ?? '',
      };
  }
};
