import React from 'react';
import ProductDefinitionTable from '../../components/ProductDefinitionTable/ProductDefinitionTable';
import useProductDefinitionSelectionScreen from './useProductDefinitionSelectionScreen';

export const ProductDefinitionSelectionScreen: React.FC = (): JSX.Element => {
  const {
    productDefinitions,
    loading,
    handleNewProductDefinitionClick,
    handleEditProductDefinition,
    handleDeleteProductDefinitions,
    handleDuplicateProductDefinition,
  } = useProductDefinitionSelectionScreen();

  return (
    <ProductDefinitionTable
      productDefinitions={productDefinitions}
      isFetching={loading}
      handleEditProductDefinition={handleEditProductDefinition}
      handleDeleteProductDefinitions={handleDeleteProductDefinitions}
      handleDuplicateProductDefinition={handleDuplicateProductDefinition}
      handleNewProductDefinition={handleNewProductDefinitionClick}
    />
  );
};
