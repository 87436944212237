/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull ivtw'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 5
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/autodesk-acs/Autodesk-Informed-Design/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/autodesk-acs/Autodesk-Informed-Design/implementation/ivtw)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'test' | 'production';

export const ApiKey: Record<Environment, string> = {
  test: '',
  production: ''
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '5',
    branch: 'main',
    source: 'ivtw',
    versionId: '244b11bb-c496-4906-a480-006318c5791a'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  userEmail?: string;
  userOId?: string;
}

export interface IvtwProductDefinitionCreateProperties {
  hasBeenDuplicated?: boolean;
  hasCodeBlocksWorkspace: boolean;
  hasRules: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfDrawingThumbnails: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfInputs: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfOutputs: number;
  productDefinitionId: string;
  productDefinitionName: string;
  releaseName: string;
}

export interface IvtwProductDefinitionDeleteProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfDeletedProductDefinitions: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  productDefinitionIds: string[];
}

export interface IvtwProductDefinitionPublishProperties {
  accountId?: string;
  hasCodeBlocksWorkspace: boolean;
  hasRules: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfInputs: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  numberOfOutputs: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  outputTypes: string[];
  productId: string;
  productName: string;
  projectId: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  releaseNumber: number;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IvtwProductDefinitionCreate implements BaseEvent {
  event_type = 'ivtw.productDefinition.create';

  constructor(
    public event_properties: IvtwProductDefinitionCreateProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IvtwProductDefinitionDelete implements BaseEvent {
  event_type = 'ivtw.productDefinition.delete';

  constructor(
    public event_properties: IvtwProductDefinitionDeleteProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class IvtwProductDefinitionPublish implements BaseEvent {
  event_type = 'ivtw.productDefinition.publish';

  constructor(
    public event_properties: IvtwProductDefinitionPublishProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

  /**
   * Set Group for the current user
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  setGroup(groupType: string, groupName: string | string[], options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.setGroup(groupType, groupName, options);
  }

  /**
   * Identify a group and set group properties.
   *
   * @param groupType The group type.
   * @param groupName The group name.
   * @param options Optional event options.
   */
  groupIdentify(
    groupType: string,
    groupName: string | string[],
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    const amplitudeIdentify = new amplitude.Identify();
    return this.amplitude!.groupIdentify(groupType, groupName, amplitudeIdentify, options);
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * ivtw.productDefinition.create
   *
   * [View in Tracking Plan](https://data.amplitude.com/autodesk-acs/Autodesk-Informed-Design/events/main/latest/ivtw.productDefinition.create)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. hasBeenDuplicated)
   * @param options Amplitude event options.
   */
  ivtwProductDefinitionCreate(
    properties: IvtwProductDefinitionCreateProperties,
    options?: EventOptions,
  ) {
    return this.track(new IvtwProductDefinitionCreate(properties), options);
  }

  /**
   * ivtw.productDefinition.delete
   *
   * [View in Tracking Plan](https://data.amplitude.com/autodesk-acs/Autodesk-Informed-Design/events/main/latest/ivtw.productDefinition.delete)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. numberOfDeletedProductDefinitions)
   * @param options Amplitude event options.
   */
  ivtwProductDefinitionDelete(
    properties: IvtwProductDefinitionDeleteProperties,
    options?: EventOptions,
  ) {
    return this.track(new IvtwProductDefinitionDelete(properties), options);
  }

  /**
   * ivtw.productDefinition.publish
   *
   * [View in Tracking Plan](https://data.amplitude.com/autodesk-acs/Autodesk-Informed-Design/events/main/latest/ivtw.productDefinition.publish)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. accountId)
   * @param options Amplitude event options.
   */
  ivtwProductDefinitionPublish(
    properties: IvtwProductDefinitionPublishProperties,
    options?: EventOptions,
  ) {
    return this.track(new IvtwProductDefinitionPublish(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
