import CircularProgress from '@mui/material/CircularProgress';
import text from '../../inventor.text.json';
import { useTheme } from '@mui/material/styles';
import {
  ACCDocSelection,
  EMPTY_STATE_ILLUSTRATION_TYPES,
  FlexContainer,
  LocationContainer,
  MIDEmptyState,
  PreviewImage,
  PreviewInProgressMessageWrap,
  PreviewLoader,
  PreviewWrapper,
  ProductDetails,
  ProductDetailsWrapper,
  ProductFolderBrowser,
  RightButton,
  SpinnerForButton,
  useACCDocSelection,
  useProductSelection,
} from 'mid-react-common';
import React from 'react';
import useProductDefinitionManagementScreen from './useProductDefinitionManagementScreen';
import { productFolderBrowserHeight } from './ProductDefinitionManagementScreen.styles';
import { OutputType, DCRfaOutput } from '@adsk/offsite-dc-sdk';
import { FOLDERS_PERMISSION_FILTER_OPTIONS } from 'mid-types';

const productDefinitionManagementFolderPermission = FOLDERS_PERMISSION_FILTER_OPTIONS.view;

export const ProductDefinitionManagementScreen: React.FC = () => {
  const theme = useTheme();
  const {
    accounts,
    accountsLoading,
    projects,
    projectsLoading,
    selectedAccount,
    selectedProject,
    projectsDisabled,
    handleSelectAccount,
    handleSelectProject,
  } = useACCDocSelection();

  const {
    rootFoldersTreeItems,
    rootFoldersLoading,
    rootFoldersError,
    selectedFolderTreeElement,
    handleFolderClick,
    handleProductClick,
    products,
    handleDeleteProduct,
    deleteProductLoading,
    productsLoading,
    productsError,
    selectedProduct,
    setSelectedProduct,
  } = useProductSelection({
    selectedProjectId: selectedProject?.id,
    permissionFilter: productDefinitionManagementFolderPermission,
  });

  const { thumbnailError, thumbnailInBase64, thumbnailLoading, handleDeleteProductDefinitionClick } =
    useProductDefinitionManagementScreen({
      selectedAccount,
      selectedProject,
      selectedProduct,
      setSelectedProduct,
      handleDeleteProduct,
    });

  const rfaOutput = selectedProduct?.outputs.find((output): output is DCRfaOutput => output.type === OutputType.RFA);

  return (
    <LocationContainer>
      <FlexContainer>
        <ACCDocSelection
          maxHeight={productFolderBrowserHeight}
          accounts={accounts || []}
          accountsLoading={accountsLoading}
          projects={projects || []}
          projectsLoading={projectsLoading}
          selectedAccount={selectedAccount}
          selectedProject={selectedProject}
          projectsDisabled={projectsDisabled}
          handleSelectAccount={handleSelectAccount}
          handleSelectProject={handleSelectProject}
        />
        <RightButton variant="contained" disabled={!selectedProduct} onClick={handleDeleteProductDefinitionClick}>
          {text.deleteProduct}
          {deleteProductLoading && <SpinnerForButton size={theme.var.buttonSpinnerSize} color="inherit" />}
        </RightButton>
      </FlexContainer>
      <FlexContainer gap={theme.var.marginBase}>
        <ProductFolderBrowser
          projectId={selectedProject?.id || ''}
          rootFolders={rootFoldersTreeItems}
          rootFoldersLoading={rootFoldersLoading}
          rootFoldersError={rootFoldersError}
          selectedFolderTreeElement={selectedFolderTreeElement}
          products={products}
          productsLoading={productsLoading}
          productsError={productsError}
          selectedProductId={selectedProduct?.contentId}
          onFolderClick={handleFolderClick}
          onProductClick={handleProductClick}
          displayCreateNewFolderButton
          folderPermissionFilter={productDefinitionManagementFolderPermission}
          maxHeight={productFolderBrowserHeight}
        />
        {selectedProduct?.contentId && (
          <ProductDetailsWrapper>
            <PreviewWrapper>
              {thumbnailLoading ? (
                <PreviewLoader>
                  <CircularProgress size={theme.var.circularProgressSize} thickness={theme.var.circularProgressThickness} />
                  <PreviewInProgressMessageWrap>{text.revitLoadingThumbnail}</PreviewInProgressMessageWrap>
                </PreviewLoader>
              ) : thumbnailInBase64 ? (
                <PreviewImage src={`data:image/jpeg;base64,${thumbnailInBase64}`} alt={text.previewImageAltText} />
              ) : (
                <MIDEmptyState
                  illustrationType={
                    thumbnailError ? EMPTY_STATE_ILLUSTRATION_TYPES.SYSTEM_ERROR : EMPTY_STATE_ILLUSTRATION_TYPES.PAGES_PHOTO
                  }
                  title={thumbnailError ? text.revitPreviewNotAvailable : ''}
                />
              )}
            </PreviewWrapper>
            <ProductDetails
              title={selectedProduct.name || ''}
              productCategory={rfaOutput?.options?.category || ''}
              productFamily={rfaOutput?.options?.family || ''}
              representations={rfaOutput?.options?.modelStates || []}
            />
          </ProductDetailsWrapper>
        )}
      </FlexContainer>
    </LocationContainer>
  );
};
