import Typography from '@mui/material/Typography';
import React from 'react';
import text from '../../../../inventor.text.json';
import { SourceContentForm } from '../SourceContentTab.styles';
import BaseModelSection from './BaseModelSection';
import ProjectSection from './ProjectSection';
import RootFolderSection from './RootFolderSection';
import { TopLevelFolderOptions } from '../useSourceContent';
interface ModelFormProps {
  topLevelFolder: string;
  relativeAssemblyPath: string;
  relativeInventorProjectPath: string;
  handleSelectInventorProjectClick: () => Promise<void>;
  topLevelFolderOptions: TopLevelFolderOptions[];
  handleTopLevelFolderChange: (newTopLevelFolder: string) => void;
}

const ModelForm: React.FC<ModelFormProps> = ({
  topLevelFolder,
  relativeAssemblyPath,
  relativeInventorProjectPath,
  handleSelectInventorProjectClick,
  topLevelFolderOptions,
  handleTopLevelFolderChange,
}): JSX.Element => (
  <SourceContentForm>
    <Typography variant="h2">{text.sourceContentTitle}</Typography>
    <BaseModelSection topLevelAssembly={relativeAssemblyPath} />
    <RootFolderSection
      topLevelFolder={topLevelFolder}
      topLevelFolderOptions={topLevelFolderOptions}
      handleTopLevelFolderChange={handleTopLevelFolderChange}
    />
    <ProjectSection iPJ={relativeInventorProjectPath} handleSelectInventorProjectClick={handleSelectInventorProjectClick} />
  </SourceContentForm>
);

export default ModelForm;
