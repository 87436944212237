import { GridFilterModel, GridSelectionModel } from '@mui/x-data-grid';
import text from 'inventor.text.json';
import { IProperty, InventorParameter } from 'mid-addin-lib';
import { MIDEmptyState, TableEmptyStateWrapper } from 'mid-react-common';
import { useEffect, useState } from 'react';
import { filterIProperties, filterParameters } from './utils';
import { InputHeaderCurrentFilter } from './useInputsHeader';

export interface UseInputsTableState {
  tableData: (InventorParameter | IProperty)[];
  filteredData: GridFilterModel;
  onInputSelection: (selectedInputs: GridSelectionModel) => void;
  renderEmptyState: () => JSX.Element;
}

export interface UseInputsTableArgs {
  initialTableData: (InventorParameter | IProperty)[] | null;
  isParameter: boolean;
  currentFilter: InputHeaderCurrentFilter;
  setSelectedInputIds: (id: GridSelectionModel) => void;
}

export const useInputsTable = ({
  initialTableData,
  currentFilter,
  isParameter,
  setSelectedInputIds,
}: UseInputsTableArgs): UseInputsTableState => {
  const [tableData, setTableData] = useState<(InventorParameter | IProperty)[]>(initialTableData || []);
  const [filteredData, setFilteredData] = useState<GridFilterModel>({ items: [] });

  const onInputSelection = (selectedInputs: GridSelectionModel) => {
    setSelectedInputIds(selectedInputs);
  };

  useEffect(() => {
    if (initialTableData) {
      setTableData(initialTableData);
    }
  }, [initialTableData]);

  useEffect(() => {
    const filteredData = isParameter
      ? filterParameters(currentFilter.parameters)
      : filterIProperties(currentFilter.properties);
    setFilteredData(filteredData);
  }, [currentFilter, isParameter]);

  const renderEmptyState = (): JSX.Element => (
    <TableEmptyStateWrapper>
      <MIDEmptyState title={isParameter ? text.EmptyStateNoParameters : text.EmptyStateNoIProperties} />
    </TableEmptyStateWrapper>
  );

  return {
    tableData,
    filteredData,
    onInputSelection,
    renderEmptyState,
  };
};
