import { javascriptGenerator } from 'blockly/javascript';
import { BlocklyInputParameterDropdownValues } from '../BlocklyModule.types';
import {
  blocklyCommentBlockName,
  blocklyConnectingBlock,
  blocklyDropdown,
  blocklyFunctionsDropdown,
  commentBlock,
  universalInputBlock,
  universalOutputBlock,
} from '../constants';
import { BlockSvg } from 'blockly';
import {
  codeRunnerGetInput,
  codeRunnerSetOutput,
  HIGHLIGHT_CODE_BLOCK,
  loopTrapVariableName,
  parametersVariableName,
} from '@adsk/informed-design-code-runner';

/*
 * This file is used to tell Blockly how the custom blocks generate code.
 */

javascriptGenerator[universalInputBlock] = function (this: BlockSvg) {
  const dropdownParameter = this.getField(blocklyDropdown)?.getValue();
  const dropdownAction = this.getField(blocklyFunctionsDropdown)?.getValue();

  const valueConnectingBlock = javascriptGenerator.valueToCode(
    this,
    blocklyConnectingBlock,
    javascriptGenerator.ORDER_ATOMIC,
  );

  const dropdownValue: BlocklyInputParameterDropdownValues = JSON.parse(dropdownParameter);
  return `${parametersVariableName}['${dropdownValue.name}'].${dropdownAction} = ${valueConnectingBlock};\n`;
};

javascriptGenerator[universalOutputBlock] = function (this: BlockSvg) {
  const dropdownParameter = this.getField(blocklyDropdown)?.getValue();
  const dropdownAction = this.getField(blocklyFunctionsDropdown)?.getValue();

  const dropdownValue: BlocklyInputParameterDropdownValues = JSON.parse(dropdownParameter);

  const code = `${parametersVariableName}['${dropdownValue.name}'].${dropdownAction}`;
  return [code, javascriptGenerator.ORDER_NONE];
};

javascriptGenerator[commentBlock] = function (this: BlockSvg) {
  const comment = this.getField(blocklyCommentBlockName)?.getValue();
  return `/* ${comment} */`;
};

// This is set to prevent user of overriding the codeRunner functions/variables/constants
javascriptGenerator.addReservedWords(
  `${loopTrapVariableName},${parametersVariableName},${codeRunnerGetInput},${codeRunnerSetOutput},${HIGHLIGHT_CODE_BLOCK}`,
);
