import { getProductDefinitions } from 'mid-addin-lib';
import text from 'inventor.text.json';
import Typography from '@mui/material/Typography';

const CHARACTER_LIMIT = 255;
const SPECIAL_CHARACTERS = /[\\/:*?"<>|]/;

export const validateProductDefinitionName = async (
  productName: string,
  productDefinitionId: string | undefined,
): Promise<{
  error: boolean;
  cause: string | null;
}> => {
  const allProductDefinitions = await getProductDefinitions(false);
  const productDefinitionWithTheSameName = allProductDefinitions.find((pd) => pd.name === productName);

  // If the current product definition name is longer than 255 characters
  if (productName.length > CHARACTER_LIMIT) {
    return { error: true, cause: text.productDefinitionCharacterLimit };
  }

  // if the new product definition name is empty
  if (productName === '') {
    return { error: true, cause: text.productDefinitionEmptyNamePrompt };
  }

  // if the new product definition name is not unique
  if (productDefinitionWithTheSameName && productDefinitionWithTheSameName.id !== productDefinitionId) {
    return { error: true, cause: text.productDefinitionUniqueNamePrompt };
  }

  // in other cases the name is valid
  return { error: false, cause: null };
};

export const validateProductName = (productName: string): string | null => {
  if (productName.length > CHARACTER_LIMIT) {
    return text.productDefinitionCharacterLimit;
  }

  // check if product name contains special characters: /\:*?"<>|
  if (SPECIAL_CHARACTERS.test(productName)) {
    return text.productNameCantContainSpecialCharacters;
  }

  return null;
};

export const getNotificationBody = (messageBody: string[]): JSX.Element => (
  <>
    {messageBody.map((message, index) => (
      <Typography key={`${message}-${index}`} variant="body2">
        {message}
      </Typography>
    ))}
  </>
);
