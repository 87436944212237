import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

export const TabsWrapper = styled(Tab)`
  min-height: ${({ theme }) => `${theme.var.tabsHeight - 1}px`};
`;

export const TabPanelWrapper = styled(TabPanel)`
  padding: 0;
  margin: 0;
`;

export const TabsContainer = styled('div')<{ visibility?: boolean }>`
  display: ${({ visibility }) => (visibility ? 'block' : 'none')};
`;
