import {
  FileFilter,
  getActiveDocumentInfo,
  selectFile,
  getPathSeparator,
  ProductDefinitionOutput,
  createFullPath,
} from 'mid-addin-lib';
import { useLogAndShowNotification, useAsyncFetchData } from 'mid-react-common';
import { useContext, useEffect, useMemo, useCallback, useState } from 'react';
import DataContext from '../../../context/DataStore/Data.context';
import { InventorActiveDocumentInfo } from 'mid-addin-lib';
import { pathSeparators } from 'mid-utils';
import { DrawingThumbnail } from 'mid-types';

export interface TopLevelFolderOptions {
  folder: string;
  path: string;
}

interface UseSourceContentState {
  initialName: string;
  topLevelFolder: string;
  inventorProjectPath: string;
  assemblyPath: string;
  handleSelectInventorProjectClick: () => Promise<void>;
  topLevelFolderOptions: TopLevelFolderOptions[];
  handleTopLevelFolderChange: (newTopLevelFolder: string) => void;
}

export const useSourceContent = (): UseSourceContentState => {
  const {
    currentProductDefinition,
    setCurrentProductDefinitionName,
    setCurrentProductDefinitionSourceModel,
    deleteCurrentProductDefinitionDrawingOutputs,
    setCurrentProductDefinitionDrawingsThumbnails,
    addCurrentProductDefinitionOutputs,
    setAreDrawingFilesFetched,
  } = useContext(DataContext);
  const { name, topLevelFolder, assembly, inventorProject, outputs, drawingThumbnails } = currentProductDefinition;
  const [initialName, setInitialName] = useState<string>('');

  /*
   * Assembly
   */

  const { data: inventorDocumentInfo, error: inventorDocumentInfoError } =
    useAsyncFetchData<InventorActiveDocumentInfo>(getActiveDocumentInfo);
  useLogAndShowNotification(inventorDocumentInfoError, inventorDocumentInfoError?.message || '');

  useEffect(() => {
    if (inventorDocumentInfo && !assembly) {
      setCurrentProductDefinitionSourceModel({
        assembly: createFullPath(inventorDocumentInfo.location, inventorDocumentInfo.name),
      });
    }
  }, [assembly, inventorDocumentInfo, setCurrentProductDefinitionSourceModel]);

  /*
   * Initialize Product Definition Name
   */

  useEffect(() => {
    if (inventorDocumentInfo) {
      setInitialName(inventorDocumentInfo.name.replace('.ipt', '').replace('.iam', ''));
      if (!name) {
        setCurrentProductDefinitionName(inventorDocumentInfo.name.replace('.ipt', '').replace('.iam', ''));
      }
    }
    // We only want to set the name initially when it's a new product definition and never again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventorDocumentInfo]);

  // If file is part of top level folder, return a relative path,
  // Otherwise return an absolute path
  const getPathRelativeToTopLevelFolder = (fullPath: string, topLevelFolder: string): string =>
    fullPath.replace(topLevelFolder, '');

  const initialTopLevelFolder = inventorDocumentInfo?.location;

  /*
   * IPJ
   */
  const handleSelectInventorProjectClick = useCallback(async () => {
    if (!initialTopLevelFolder && !topLevelFolder) {
      return;
    }

    const ipjFilter: FileFilter[] = [{ name: 'Project Files(*.ipj)', expression: '*.ipj' }];
    const [inventorProject] = await selectFile(ipjFilter, false, topLevelFolder || initialTopLevelFolder);
    if (inventorProject) {
      const updatedInventorProject = topLevelFolder
        ? getPathRelativeToTopLevelFolder(inventorProject, topLevelFolder)
        : inventorProject;

      setCurrentProductDefinitionSourceModel({
        inventorProject: updatedInventorProject,
      });
    }
  }, [initialTopLevelFolder, topLevelFolder, setCurrentProductDefinitionSourceModel]);

  /**
   * Dropdown options for different top level folders
   */
  const topLevelFolderOptions: TopLevelFolderOptions[] = useMemo(() => {
    if (!initialTopLevelFolder) {
      return [];
    }

    const pathSeperator = getPathSeparator(initialTopLevelFolder);
    const folders = initialTopLevelFolder.split(pathSeperator);
    // Create a tree-ish options in Dropdown
    const FIRST_FOLDER_INDEX = 0;
    const folderOptions: TopLevelFolderOptions[] = folders.map((folder, index) => ({
      folder,
      path: folders.slice(FIRST_FOLDER_INDEX, index + 1).join(pathSeperator),
    }));

    // Remove first element since it is pointed to root of Drive (C:\ or D:\)
    return folderOptions.slice(1);
  }, [initialTopLevelFolder]);

  /**
   * Beginning of logic for handling top level change
   */
  const handleTopLevelFolderChangeForDrawingOutputs = useCallback(
    (newTopLevelFolder: string) => {
      const existingDrawingOutputs = outputs.filter((output) => !!output.options?.drawingTemplatePath);
      const allRelativePathsOfDrawings = existingDrawingOutputs.reduce<string[]>(
        (drawingPaths, drawing) =>
          drawing.options?.drawingTemplatePath ? [...drawingPaths, drawing.options.drawingTemplatePath] : drawingPaths,
        [],
      );
      // Delete existing drawing outputs and re-insert all drawing outputs with updated paths
      deleteCurrentProductDefinitionDrawingOutputs(allRelativePathsOfDrawings);

      const drawingOutputsWithUpdatedPaths = existingDrawingOutputs.reduce<ProductDefinitionOutput[]>(
        (updatedOutputs, output) => {
          const relativePath = output.options?.drawingTemplatePath;

          if (!relativePath) {
            return updatedOutputs;
          }

          // Convert existing relative path to an absolute path and test to see
          // if new top level folder is part of absolute path
          const absolutePathOfExistingDrawing = createFullPath(topLevelFolder, relativePath);
          if (absolutePathOfExistingDrawing.startsWith(newTopLevelFolder)) {
            return [
              ...updatedOutputs,
              {
                options: {
                  drawingTemplatePath: getPathRelativeToTopLevelFolder(absolutePathOfExistingDrawing, newTopLevelFolder),
                },
                type: output.type,
              },
            ];
          }

          return updatedOutputs;
        },
        [],
      );

      addCurrentProductDefinitionOutputs(drawingOutputsWithUpdatedPaths);
    },
    [topLevelFolder, outputs, deleteCurrentProductDefinitionDrawingOutputs, addCurrentProductDefinitionOutputs],
  );

  const handleTopLevelFolderChangeForDrawingThumbnails = useCallback(
    (newTopLevelFolder: string) => {
      if (drawingThumbnails) {
        const drawingThumbnailsWithUpdatedPaths = drawingThumbnails.reduce<DrawingThumbnail[]>(
          (updatedDrawingThumbnails, drawing) => {
            const absolutePath = createFullPath(topLevelFolder, drawing.relativePath);

            if (absolutePath.startsWith(newTopLevelFolder)) {
              return [
                ...updatedDrawingThumbnails,
                { ...drawing, relativePath: getPathRelativeToTopLevelFolder(absolutePath, newTopLevelFolder) },
              ];
            }

            return updatedDrawingThumbnails;
          },
          [],
        );
        setCurrentProductDefinitionDrawingsThumbnails(drawingThumbnailsWithUpdatedPaths);
      }
    },
    [drawingThumbnails, topLevelFolder, setCurrentProductDefinitionDrawingsThumbnails],
  );

  const handleTopLevelFolderChangeForAssemblyAndIpj = useCallback(
    (newTopLevelFolder: string) => {
      const assemblyAbsolutePath = topLevelFolder ? createFullPath(topLevelFolder, assembly) : assembly;
      // Inventor project can be either absolute path, relative path or empty path
      const inventorProjectAbsolutePath =
        topLevelFolder && inventorProject.startsWith(pathSeparators.BACKSLASH)
          ? createFullPath(topLevelFolder, inventorProject)
          : inventorProject;

      setCurrentProductDefinitionSourceModel({
        assembly: getPathRelativeToTopLevelFolder(assemblyAbsolutePath, newTopLevelFolder),
        inventorProject: getPathRelativeToTopLevelFolder(inventorProjectAbsolutePath, newTopLevelFolder),
        topLevelFolder: newTopLevelFolder,
      });
    },
    [topLevelFolder, assembly, inventorProject, setCurrentProductDefinitionSourceModel],
  );

  const handleTopLevelFolderChange = useCallback(
    (newTopLevelFolder: string): void => {
      if (!initialTopLevelFolder || newTopLevelFolder === topLevelFolder) {
        return;
      }

      // If top level folder has not been set, then drawings and drawing outputs have not been set previously
      if (topLevelFolder) {
        handleTopLevelFolderChangeForDrawingOutputs(newTopLevelFolder);
        handleTopLevelFolderChangeForDrawingThumbnails(newTopLevelFolder);
      }

      handleTopLevelFolderChangeForAssemblyAndIpj(newTopLevelFolder);
      // Trigger to rescan the folder to see if there are new drawings
      setAreDrawingFilesFetched(false);
    },
    [
      initialTopLevelFolder,
      topLevelFolder,
      handleTopLevelFolderChangeForDrawingThumbnails,
      handleTopLevelFolderChangeForDrawingOutputs,
      handleTopLevelFolderChangeForAssemblyAndIpj,
      setAreDrawingFilesFetched,
    ],
  );
  /**
   * End of logic for handling top level change
   */

  return {
    initialName,
    topLevelFolder,
    inventorProjectPath: inventorProject,
    assemblyPath: assembly,
    handleSelectInventorProjectClick,
    topLevelFolderOptions,
    handleTopLevelFolderChange,
  };
};

export default useSourceContent;
