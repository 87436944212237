import { useContext, useEffect } from 'react';
import { NOTIFICATION_STATUSES, NotificationContext, useAsyncPostData } from 'mid-react-common';
import { ProductDefinition, upsertProductDefinition } from 'mid-addin-lib';
import { logError } from 'mid-utils';
import DataContext from 'context/DataStore/Data.context';
import TabProgressContext from 'context/TabProgressStore/TabProgress.context';
import { TABS } from 'context/TabProgressStore/tabProgressStore';
import { TabProgress } from 'types';
import text from 'inventor.text.json';
import { ampli } from '../../../ampli';
import { isUndefined } from 'lodash';

type UseProductDefinitionConfigurationHeaderState = {
  readyToPublish: boolean;
  saveProductDefinitionLoading: boolean;
  handleSaveProductDefinition: () => void;
};

export const useProductDefinitionConfigurationHeader = (): UseProductDefinitionConfigurationHeaderState => {
  const { currentProductDefinition, setCurrentProductDefinition } = useContext(DataContext);
  const { tabProgress } = useContext(TabProgressContext);
  const { showNotification } = useContext(NotificationContext);

  const readyToPublish =
    tabProgress[TABS.SOURCE_CONTENT] === TabProgress.COMPLETED &&
    tabProgress[TABS.INPUTS] === TabProgress.COMPLETED &&
    tabProgress[TABS.RULES] === TabProgress.COMPLETED &&
    tabProgress[TABS.OUTPUTS] === TabProgress.COMPLETED;

  const {
    postFn: wrappedSavedProductDefinition,
    loading: saveProductDefinitionLoading,
    error: saveProductDefinitionLoadingError,
  } = useAsyncPostData<ProductDefinition>(upsertProductDefinition);

  const handleSaveProductDefinition = async () => {
    const savedProductDefinition = await wrappedSavedProductDefinition(currentProductDefinition);
    if (savedProductDefinition) {
      // Amplitude Event
      // We only send the event when is the first time we save the product definition
      if (productDefinitionHasNeverBeenSaved(currentProductDefinition.id) && savedProductDefinition.id) {
        ampli.ivtwProductDefinitionCreate({
          productDefinitionId: savedProductDefinition.id,
          productDefinitionName: savedProductDefinition.name,
          numberOfInputs: savedProductDefinition.inputs.length,
          hasRules: savedProductDefinition.rules.length > 0,
          hasCodeBlocksWorkspace: !!savedProductDefinition.codeBlocksWorkspace,
          numberOfOutputs: savedProductDefinition.outputs.length,
          numberOfDrawingThumbnails: savedProductDefinition.drawingThumbnails?.length || 0,
          releaseName: savedProductDefinition.releaseName,
        });
      }

      setCurrentProductDefinition({
        ...savedProductDefinition,
      });
      showNotification({
        message: text.notificationSavedProductDefinitionSuccess,
        severity: NOTIFICATION_STATUSES.SUCCESS,
      });
    }
  };

  useEffect(() => {
    if (saveProductDefinitionLoadingError) {
      logError(saveProductDefinitionLoadingError);
      showNotification({
        message: `${text.notificationSavedProductDefinitionFailed} ${saveProductDefinitionLoadingError.message}`,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  }, [saveProductDefinitionLoadingError, showNotification]);

  return {
    readyToPublish,
    saveProductDefinitionLoading,
    handleSaveProductDefinition,
  };
};

const productDefinitionHasNeverBeenSaved = (id?: string | undefined): boolean => isUndefined(id) || id === '';
