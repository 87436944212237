import './FormCodeblocks.mutators';
import './FormCodeblocks.blocks';
import { BlocklyPlaceholder } from '../BlocklyModule.styles';
import { InputRule, ProductDefinitionInput, SerializedBlocklyWorkspaceState } from 'mid-addin-lib';
import inventorTestIds from '../../../inventor.testids';
import useFormCodeblocks from './useFormCodeblocks';

interface FormLayoutCodeblocksProps {
  hidden: boolean;
  inputs: ProductDefinitionInput[];
  formCodeblocksWorkspace?: SerializedBlocklyWorkspaceState;
  setFormCodeBlocksWorkspace: (workspace: SerializedBlocklyWorkspaceState) => void;
  setFormCodeBlocksRules: (rule: InputRule) => void;
  recentlyAdoptedInputs: ProductDefinitionInput[];
  setRecentlyAdoptedInputs: (inputs: ProductDefinitionInput[]) => void;
}

const { blocklyFormWorkspaceTestId } = inventorTestIds;

const FormLayoutCodeblocks: React.FC<FormLayoutCodeblocksProps> = ({
  hidden,
  inputs,
  formCodeblocksWorkspace,
  setFormCodeBlocksWorkspace,
  setFormCodeBlocksRules,
  recentlyAdoptedInputs,
  setRecentlyAdoptedInputs,
}): JSX.Element => {
  const { blocklyDiv } = useFormCodeblocks({
    hidden,
    inputs,
    formCodeblocksWorkspace,
    setFormCodeBlocksWorkspace,
    setFormCodeBlocksRules,
    recentlyAdoptedInputs,
    setRecentlyAdoptedInputs,
  });

  return <BlocklyPlaceholder ref={blocklyDiv} hidden={hidden} data-testid={blocklyFormWorkspaceTestId} />;
};

export default FormLayoutCodeblocks;
