import { styled } from '@mui/material/styles';

export const OutputsSummaryTableContainer = styled('table')`
  width: 100%;
  border-spacing: ${({ theme }) => `${theme.var.paddingBase}px ${theme.var.paddingBase}px`};
  border-collapse: collapse;
`;

export const OutputsSummaryTableHeader = styled('thead')`
  padding: ${({ theme }) => `0 ${theme.var.paddingBase}px`};
  height: ${({ theme }) => `${theme.var.productDefinitionTableHeaderHeight}px`};
`;

export const OutputsSummaryTableHeaderCell = styled('td')`
  text-align: left;
  padding-left: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
`;

export const OutputsSummaryTableCell = styled('td')`
  padding: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
`;
export const OutputsSummaryTableRow = styled('tr')`
  border-bottom: 1px solid ${({ theme }) => `${theme.palette.divider}`};
  padding: ${({ theme }) => `${theme.var.paddingBase * 2}px`};
`;

export const OutputsSummaryTableHeaderRow = styled('tr')`
  border-bottom: 2px solid ${({ theme }) => `${theme.palette.divider}`};
`;
