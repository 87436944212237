import { ContextMenuRegistry, WorkspaceSvg } from 'blockly';
import { CONTEXT_MENU_DISABLED, CONTEXT_MENU_ENABLED, CONTEXT_MENU_HIDDEN, CONTEXT_MENU_IMPORT } from '../constants';
import text from 'inventor.text.json';
import { BlocklyPluginPreconditionReturn } from '../types';

export const initializeImportPlugin = (workspace: WorkspaceSvg, onImport: (workspace: WorkspaceSvg) => void): void => {
  if (!workspace) {
    return;
  }

  /*
   * Context Menu Override
   */
  const ContextMenuImport = {
    displayText: text.importBlocksContextMenuItem,
    preconditionFn: (scope: ContextMenuRegistry.Scope): BlocklyPluginPreconditionReturn => {
      // do not show the import context menu item on a Form workspace or a Flyout sidebar
      if (scope.workspace && !scope.workspace.options.plugins[CONTEXT_MENU_IMPORT]) {
        return CONTEXT_MENU_HIDDEN;
      }

      if (scope.workspace) {
        return CONTEXT_MENU_ENABLED;
      }

      return CONTEXT_MENU_DISABLED;
    },
    callback: (scope: ContextMenuRegistry.Scope) => scope.workspace && onImport(scope.workspace),
    scopeType: ContextMenuRegistry.ScopeType.WORKSPACE,
    id: CONTEXT_MENU_IMPORT,
    weight: 1,
  };

  // prevent double registering in cypress tests
  if (ContextMenuRegistry.registry.getItem(CONTEXT_MENU_IMPORT)) {
    ContextMenuRegistry.registry.unregister(CONTEXT_MENU_IMPORT);
  }
  ContextMenuRegistry.registry.register(ContextMenuImport);
};
