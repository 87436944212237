import { useContext, useEffect } from 'react';
import DataContext from '../../../../context/DataStore/Data.context';
import { InventorProperties } from 'mid-addin-lib';

type UseParametersDefaults = {
  inventorData: InventorProperties | null;
};

const useParametersDefaults = ({ inventorData }: UseParametersDefaults): void => {
  const { currentProductDefinition, setCurrentProductDefinitionParametersDefaultsByInventorParameters } =
    useContext(DataContext);

  // initialize parameter defaults (one time operation)
  useEffect(() => {
    if (!inventorData) {
      return;
    }

    const parametersDefaultsNotSet =
      !currentProductDefinition.parametersDefaults || !currentProductDefinition.parametersDefaults.length;

    if (parametersDefaultsNotSet) {
      setCurrentProductDefinitionParametersDefaultsByInventorParameters(inventorData.parameters);
    }
  }, [
    inventorData,
    currentProductDefinition.parametersDefaults,
    setCurrentProductDefinitionParametersDefaultsByInventorParameters,
  ]);

  // replace the inventorData parameters values with their defaults if applicable
  useEffect(() => {
    if (!inventorData) {
      return;
    }

    if (!currentProductDefinition.parametersDefaults || !currentProductDefinition.parametersDefaults.length) {
      return;
    }

    const parameterDefaultsMap = new Map(
      currentProductDefinition.parametersDefaults.map((parameterDefault) => [parameterDefault.name, parameterDefault.value]),
    );

    inventorData.parameters.forEach((parameter) => {
      if (!parameterDefaultsMap.has(parameter.name)) {
        return;
      }

      const parameterDefault = parameterDefaultsMap.get(parameter.name);
      if (parameterDefault) {
        parameter.value = String(parameterDefault);
      }
    });
  }, [inventorData, currentProductDefinition.parametersDefaults]);
};

export default useParametersDefaults;
