import Typography from '@mui/material/Typography';
import text from 'inventor.text.json';
import { ProductDefinition } from 'mid-addin-lib';
import { SummaryTable, SummaryTableRow } from 'mid-react-common';
import React from 'react';
import { ProductDefinitionSummary, PublishTableContainer } from './Publishing.styles';
import { publishTestIds } from '../../tests/helpers/dataTestIds';
import Divider from '@mui/material/Divider';
import { getProductDefinitionSummaryData, getReleaseInfoSummaryData, getRFASummaryData } from './publishing.utils';

export interface PublishingFailedProps {
  currentProductDefinition: ProductDefinition;
  publishedProductName: string;
}

export const PublishingFailed: React.FC<PublishingFailedProps> = ({
  currentProductDefinition,
  publishedProductName,
}): JSX.Element => {
  const releaseInfoData: SummaryTableRow[] = getReleaseInfoSummaryData(
    publishedProductName,
    currentProductDefinition,
    undefined,
    true,
  );
  const productDefinitionSummaryData: SummaryTableRow[] = getProductDefinitionSummaryData(currentProductDefinition);
  const rfaSummaryData: SummaryTableRow[] = getRFASummaryData(currentProductDefinition);

  return (
    <>
      <ProductDefinitionSummary data-testid={publishTestIds.publishReleaseSummarySection}>
        <Typography variant="h2" fontWeight="bolder" gutterBottom>
          {text.publishRelease}
        </Typography>
        <PublishTableContainer className="mid-border-shadow">
          <SummaryTable data={releaseInfoData} removeBorder removeBackground />
        </PublishTableContainer>
      </ProductDefinitionSummary>
      <ProductDefinitionSummary data-testid={publishTestIds.productDefinitionSummarySection}>
        <Typography variant="h2" fontWeight="bolder" gutterBottom>
          {text.publishSummaryTableTitle}
        </Typography>
        <PublishTableContainer className="mid-border-shadow">
          <SummaryTable data={productDefinitionSummaryData} removeBorder removeBackground />
          <Divider />
          <SummaryTable data={rfaSummaryData} removeBorder removeBackground />
        </PublishTableContainer>
      </ProductDefinitionSummary>
    </>
  );
};
