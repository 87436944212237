import { ProductDefinition, ProductDefinitionInputParameter, ProductDefinitionOutput } from 'mid-addin-lib';
import Skeleton from '@mui/material/Skeleton';
import { OutputType, OutputTypes } from '@adsk/offsite-dc-sdk';
import text from 'inventor.text.json';
import { FlexContainer, SummaryTableRow } from 'mid-react-common';
import { uniq } from 'lodash';
import WarningIcon from '@mui/icons-material/Warning';
import { midTheme } from 'mid-react-common';
import { isNotProductDefinitionIProperty } from 'utils/typeGuards';
import { MetaInfoPath } from 'mid-types';

const SLASH = ' / ';

export interface OutputsSummaryTableData {
  outputType: string | JSX.Element;
  fileTypes: string | JSX.Element;
  representations: string | JSX.Element;
}

const extractRFAOutputType = (currentProductDefinition: Partial<ProductDefinition>): ProductDefinitionOutput | undefined =>
  currentProductDefinition.outputs?.filter((output) => output.type === OutputType.RFA)[0];

const extractRfaModelStates = (currentProductDefinition: Partial<ProductDefinition>): string | undefined =>
  currentProductDefinition.outputs
    ?.reduce((acc: string[], output) => {
      if (output.type === OutputType.RFA && output.options?.modelStates) {
        return [...acc, ...output.options.modelStates];
      }
      return acc;
    }, [])
    .join(', ');

const getParameters = (
  currentProductDefinition: Partial<ProductDefinition>,
): ProductDefinitionInputParameter[] | undefined => currentProductDefinition.inputs?.filter(isNotProductDefinitionIProperty);

export const getProductDefinitionSummaryData = (currentProductDefinition: Partial<ProductDefinition>): SummaryTableRow[] => {
  const parameters = getParameters(currentProductDefinition);
  return [
    {
      title: text.publishProductDefinitionName,
      value: currentProductDefinition.name || <Skeleton />,
    },
    {
      title: text.sourceContentBaseModel,
      value: currentProductDefinition.assembly || <Skeleton />,
    },
    {
      title: text.publishProductDefinitionParametersCount,
      value: parameters?.length.toString() || <Skeleton />,
    },
    {
      title: text.publishProductDefinitionPropertiesCount,
      value:
        currentProductDefinition.inputs && parameters ? (
          (currentProductDefinition.inputs.length - parameters.length).toString()
        ) : (
          <Skeleton />
        ),
    },
  ];
};

export const getRFASummaryData = (currentProductDefinition: Partial<ProductDefinition>): SummaryTableRow[] => [
  {
    title: text.publishProductDefinitionRevitCategory,
    value: extractRFAOutputType(currentProductDefinition)?.options?.category || <Skeleton />,
  },
  {
    title: text.publishProductDefinitionRevitFamily,
    value: extractRFAOutputType(currentProductDefinition)?.options?.family || <Skeleton />,
  },
  {
    title: text.publishRepresentations,
    value: extractRfaModelStates(currentProductDefinition) || <Skeleton />,
  },
];

const getFullPublishLocationPath = (accountName: string, projectName: string, folder: MetaInfoPath): string => {
  const publishLocationPathParts = [
    accountName,
    projectName,
    ...folder.parentPath.map((parentPath) => parentPath.name),
    folder.name,
  ];
  return publishLocationPathParts.join(' / ');
};

export const getReleaseInfoSummaryData = (
  publishedProductName: string,
  currentProductDefinition: Partial<ProductDefinition>,
  release?: number,
  hasFailed?: boolean,
): SummaryTableRow[] => {
  const releaseInfoData: SummaryTableRow[] = [];
  if (hasFailed) {
    releaseInfoData.push({
      title: text.publishRelease,
      value: (
        <FlexContainer alignItems="center" gap={midTheme.var.marginBase}>
          <WarningIcon color="error" /> {text.publishNotPublished}
        </FlexContainer>
      ),
    });
  } else if (release) {
    releaseInfoData.push({
      title: text.publishRelease,
      value: release.toString() || <Skeleton />,
    });
  }
  return [
    {
      title: text.publishReleaseName,
      value: publishedProductName || <Skeleton />,
    },
    ...releaseInfoData,
    {
      title: text.publishLocationTitle,
      value:
        currentProductDefinition.account && currentProductDefinition.project && currentProductDefinition.folder ? (
          getFullPublishLocationPath(
            currentProductDefinition.account.name,
            currentProductDefinition.project.name,
            currentProductDefinition.folder,
          )
        ) : (
          <Skeleton />
        ),
    },
  ];
};

export const getProductOutputsSummaryData = (
  currentProductDefinition: Partial<ProductDefinition>,
): OutputsSummaryTableData[] => {
  const outputs = currentProductDefinition.outputs;
  const summaryData = [];

  // Model 3d
  if (outputs && outputs.some((output) => output.type === OutputType.IAM)) {
    summaryData.push({
      outputType: text.outputsInventorModel,
      fileTypes: text.publishIamFileTypes,
      representations: text.publishAll,
    });
  }

  // Drawings 2d
  const drawing2dFormats: OutputTypes[] = [OutputType.DWG, OutputType.IDW, OutputType.PDF];
  const availableDrawings = outputs?.filter((output) => drawing2dFormats.includes(output.type));

  if (outputs && availableDrawings && availableDrawings.length) {
    const availableDrawingsTypes = [];
    if (availableDrawings.some((output) => output.type === OutputType.DWG)) {
      availableDrawingsTypes.push(`.${text.outputsDWG.toLowerCase()}`);
    }
    if (availableDrawings.some((output) => output.type === OutputType.IDW)) {
      availableDrawingsTypes.push(`.${text.outputsIDW.toLowerCase()}`);
    }
    if (availableDrawings.some((output) => output.type === OutputType.PDF)) {
      availableDrawingsTypes.push(`.${text.outputsPDF.toLowerCase()}`);
    }

    summaryData.push({
      outputType: text.outputsDrawing2D,
      fileTypes: availableDrawingsTypes.join(SLASH),
      representations: text.publishUnspecified,
    });
  }

  // Bill of materials
  const availableBoms = outputs?.filter((output) => output.type === OutputType.BOM);
  if (outputs && availableBoms && availableBoms.length) {
    const bomRepresentations = availableBoms.map((output) => output.options?.modelStates?.join(SLASH));
    summaryData.push({
      outputType: text.outputsBillOfMaterials,
      fileTypes: `.${text.outputsCSV.toLowerCase()}`,
      representations: bomRepresentations.join(SLASH),
    });
  }

  //Neutral Type
  const neutralFormats: OutputTypes[] = [OutputType.SAT, OutputType.STEP, OutputType.GLB];
  const availableNeutralFormats = outputs?.filter((output) => neutralFormats.includes(output.type));
  if (outputs && availableNeutralFormats && availableNeutralFormats.length) {
    const availableNeutralFormatTypes = [];
    if (availableNeutralFormats.some((output) => output.type === OutputType.SAT)) {
      availableNeutralFormatTypes.push(`.${text.outputsSAT.toLowerCase()}`);
    }
    if (availableNeutralFormats.some((output) => output.type === OutputType.STEP)) {
      availableNeutralFormatTypes.push(`.${text.outputsSTEP.toLowerCase()}`);
    }
    if (availableNeutralFormats.some((output) => output.type === OutputType.GLB)) {
      availableNeutralFormatTypes.push(`.${text.outputsGLB.toLowerCase()}`);
    }

    const availableNeutralFormatsModelStates = availableNeutralFormats.reduce<string[]>((acc, output) => {
      if (output.options?.modelStates) {
        return [...acc, ...output.options.modelStates];
      }
      return acc;
    }, []);

    const uniqueModelStates = uniq(availableNeutralFormatsModelStates);

    summaryData.push({
      outputType: text.outputsNeutralFormat,
      fileTypes: availableNeutralFormatTypes.join(SLASH),
      representations: uniqueModelStates.join(SLASH),
    });
  }

  if (!summaryData.length) {
    return [
      {
        outputType: <Skeleton />,
        fileTypes: <Skeleton />,
        representations: <Skeleton />,
      },
    ];
  }

  return summaryData;
};
