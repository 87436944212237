import Blockly, { BlockSvg, FieldDropdown } from 'blockly';
import { blocklyDropdown, blocklyFunctionsDropdown, customBlocklyMutators, invalidInputSymbol } from '../constants';
import {
  appendInputDropdownsToBlock,
  dropdownHasInvalidInput,
  isCurrentSelectedOptionValid,
  isSelectedInputNotValid,
  removeInvalidInput,
} from '../utils';
import text from '../../../inventor.text.json';
import { BlocklyState } from 'mid-types';

/*
 * Blockly mutators are used to tell blockly how to handle "Dynamic" blocks.
 *
 * Mutators come in handy when you want to serialize Blockly's Workspace
 *  for example when you call: Blockly.serialization.workspaces.save(blocklyWorkspace),
 *
 * In this case, we use mutators for two purposes:
 *  - to save load the values from the block's `extraState` value
 *  - to dynamically populate the dropdowns depending on the block's state
 */

export const initializeBlocklyMutators = (): void => {
  if (!Blockly.Extensions.isRegistered(customBlocklyMutators.INPUT_DROPDOWN_MUTATOR)) {
    Blockly.Extensions.registerMutator(customBlocklyMutators.INPUT_DROPDOWN_MUTATOR, {
      saveExtraState(this: BlockSvg) {
        const inputsDropdown = this.getField(blocklyDropdown) as FieldDropdown;
        const functionsDropdown = this.getField(blocklyFunctionsDropdown);

        if (inputsDropdown && functionsDropdown) {
          const inputsDropdownOptions = inputsDropdown.getOptions();
          const selectedOption = inputsDropdown.getText();

          //Check if the current value of the dropdown has a valid adopted parameter
          if (isCurrentSelectedOptionValid(selectedOption) && dropdownHasInvalidInput(inputsDropdownOptions)) {
            //Remove warning and enable block
            this.setEnabled(true);
            this.setWarningText(null);

            //Remove invalid input from dropdown
            const updatedDropdown = new Blockly.FieldDropdown(() => removeInvalidInput(inputsDropdownOptions));
            appendInputDropdownsToBlock(this, updatedDropdown, inputsDropdown.getValue());
          }

          const result: Record<string, string | number | boolean> = {
            inputsDropdown: inputsDropdown.getValue(),
            functionsDropdown: functionsDropdown.getValue(),
          };

          if (!isCurrentSelectedOptionValid(selectedOption)) {
            // save the unadopted state to make it possible to restore the enabled state once it's adopted
            // persist this property only if it's true
            result.unadopted = true;
          }

          return result;
        }
      },
      loadExtraState(this: BlockSvg, state: BlocklyState) {
        const inputsDropdown = this.getField(blocklyDropdown) as FieldDropdown;
        if (!inputsDropdown) {
          return;
        }

        const inputsDropdownOptions = inputsDropdown.getOptions();

        // Check if any input has been unadopted
        if (isSelectedInputNotValid(inputsDropdownOptions, state.inputsDropdown)) {
          const label = JSON.parse(state.inputsDropdown).name;

          // If it has been unadopted show warning and disable block
          this.setEnabled(false);
          this.setWarningText(label + text.inputHasNotBeenAdopted);

          // Add unadopted parameter to the dropdown with a warning symbol
          const dropdownWithUnadoptedInput = new Blockly.FieldDropdown(() => [
            [invalidInputSymbol + ' ' + label, state.inputsDropdown],
            ...inputsDropdownOptions,
          ]);

          appendInputDropdownsToBlock(this, dropdownWithUnadoptedInput, state.inputsDropdown);

          // Get updated Inputs Dropdown and set value
          const latestInputsDropdown = this.getField(blocklyDropdown);
          if (latestInputsDropdown) {
            latestInputsDropdown.setValue(state.inputsDropdown);
          }
        } else {
          // Set Input values
          inputsDropdown.setValue(state.inputsDropdown);

          // if the parameter was unadopted, but now it is, the enabled state should be restored
          if (state.unadopted) {
            this.setEnabled(true);
          }

          // When we set a value for Input, the extension will attach a new functions dropdown
          // we get the latest and set the value
          const functionsDropdown = this.getField(blocklyFunctionsDropdown);
          if (functionsDropdown) {
            functionsDropdown.setValue(state.functionsDropdown);
          }
        }
      },
    });
  }
};
