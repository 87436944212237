import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { Input } from './Input';

export const ItemTypes = {
  CARD: 'card',
};

export interface SortableInputProps {
  id: string;
  sortingId: string;
  name: string;
  title?: string | undefined;
  renderIcon: () => JSX.Element;
}

export const SortableInput: React.FC<SortableInputProps> = ({ id, name, sortingId, renderIcon }) => {
  const { setNodeRef, transform, transition, isDragging } = useSortable({ id: sortingId });

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0 : 1,
  };

  return (
    <div style={style} ref={setNodeRef} data-testid="sidebarInput">
      <Input id={id} sortingId={sortingId} name={name} renderIcon={renderIcon} />
    </div>
  );
};
