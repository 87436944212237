import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

export const InputsHeaderWrapper = styled('div')`
  margin: ${({ theme }) => `${theme.var.marginBase * 2}px`};
  display: flex;
`;

export const InputsSelectionTable = styled(DataGrid)`
  height: calc(100% - ${({ theme }) => `${theme.var.inputsSelectionHeaderHeight}px`});
`;

export const EmptyStateWrapper = styled('div')`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const TableContainer = styled('div')`
  height: 100%;
  flex: 3;
`;

export const AdoptedInputsContainer = styled('div')`
  height: calc(100vh - ${({ theme }) => `${theme.var.topBars}px`});
  flex: 1;
`;

export const InputsSelectionContainer = styled('div')`
  display: flex;
  height: calc(100vh - ${({ theme }) => `${theme.var.topBars}px`});
`;
