export interface ExportCodeBlocksOptions {
  fileName: string;
  fileLocation: string;
  skipDialog?: boolean;
}

export const ExportCodeBlocksStatus = {
  success: 'success',
  cancel: 'cancel',
  error: 'error',
} as const;

export type ExportCodeBlocksStatusType = (typeof ExportCodeBlocksStatus)[keyof typeof ExportCodeBlocksStatus];

export interface ExportCodeBlocksResult {
  status: ExportCodeBlocksStatusType;
  message?: string;
}

export interface ImportCodeBlocksOptions {
  fileName?: string;
  fileLocation: string;
  skipDialog?: boolean;
}

export const ImportCodeBlocksStatus = {
  success: 'success',
  cancel: 'cancel',
  error: 'error',
} as const;

export type ImportCodeBlocksStatusType = (typeof ImportCodeBlocksStatus)[keyof typeof ImportCodeBlocksStatus];

export interface ImportCodeBlocksResult {
  status: ImportCodeBlocksStatusType;
  codeBlocks?: string;
  message?: string;
}
